import { Button, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const DeleteDialogue = (props) => {
    const {
        deleteDriverDialog,
        deleteTruckDialog,
        mode,
        title,
        handleDeleteClose,
        target: {
            DriverName,
            TruckName,
            Route,
            PlanName,
            ServDaySunday,
            ServDayMonday,
            ServDayTuesday,
            ServDayWednesday,
            ServDayThursday,
            ServDayFriday,
            ServDaySaturday,
        },
    } = props;

    const { t } = useTranslation();

    const entityObject = {
        Driver: DriverName,
        Truck: TruckName,
        RouteSet: Route,
        RoutePlan: PlanName,
    };

    const getDeleteMessage = (title) => entityObject[title];

    //only applies to RoutePlanTable
    const hasUnassignedServiceDays = () => {
        return ServDaySunday || ServDayMonday || ServDayTuesday || ServDayWednesday || ServDayThursday || ServDayFriday || ServDaySaturday;
    };

    return (
        <Dialog
            open={deleteDriverDialog || deleteTruckDialog}
            maxWidth={'sm'}
            fullWidth={true}
        >
            <DialogTitle>
                {t('Common.Delete')} {t(`RoutePlans.${title}.Title`)}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                    {!hasUnassignedServiceDays()
                        ? `${t('RoutePlans.Message.DeleteConfirm', { name: getDeleteMessage(title) })}`
                        : t('RoutePlans.Message.DeleteWarning')}
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                    style={{ backgroundColor: '#ee8a1d', color: '#fff' }}
                    onClick={() => handleDeleteClose('CANCEL')}
                >
                    {!hasUnassignedServiceDays() ? t('Common.Cancel') : t('Common.OK')}
                </Button>
                {!hasUnassignedServiceDays() && (
                    <Button
                        style={{ backgroundColor: '#16a8a6', color: '#fff' }}
                        onClick={() => handleDeleteClose('DELETE')}
                    >
                        {t('Common.Yes')}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};
