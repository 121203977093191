import React from 'react';
import { RODialog, ROGreenButton, ROOrangeButton } from '../../../common';
import Grid from '@mui/material/Grid';
import DialogActions from '@mui/material/DialogActions';
import TextArea from 'devextreme-react/text-area';
import SelectBox from 'devextreme-react/select-box';
import CheckBox from 'devextreme-react/check-box';
import TextBox from 'devextreme-react/text-box';
import TreeList, { Column as TlColumn, HeaderFilter as TlHeaderFilter, SearchPanel as TlSearchPanel } from 'devextreme-react/tree-list';
import { RODialogLabel } from '../../../common';
import { useTranslation } from 'react-i18next';

export const AddEditRolesDialog = (props) => {
    const {
        showEditRoleDialog,
        roleMode,
        assignableFunctions,
        assignableRoles,
        assignableGroups,
        roleTarget,
        updateRoleForm,
        loadSavedFunctionsRolesGroups,
        refSelFunctionSelectEvent,
        refSelRoleSelectEvent,
        refSelGroupSelectEvent,
        expandedFunctionKeys,
        refRoleList,
        refGroupList,
        selFunctionKeys,
        selRoleKeys,
        selGroupKeys,
        setSelFunctionKeys,
        setSelRoleKeys,
        setSelGroupKeys,
        handleEditRoleClose,
        hasAccountManagementAccess,
        handleFunctionRowClick,
        checkAccountManagementAccess,
        getFunctionListSelectAllValue,
        getRoleListSelectAllValue,
        getGroupListSelectAllValue,
    } = props;
    const { t } = useTranslation();
    return (
        <RODialog
            maxWidth='lg'
            fullWidth={true}
            open={showEditRoleDialog}
            dialogTitle={`${roleMode === 'New' ? t('AccountManagement.Roles.Actions.AddRole') : t('AccountManagement.Roles.Actions.EditRole')}`}
            dialogActions={
                <DialogActions style={{ justifyContent: 'right' }}>
                    {roleTarget.RoleName && (
                        <ROGreenButton
                            onClick={() => {
                                handleEditRoleClose('SAVE');
                            }}
                        >
                            {t('Common.Save')}
                        </ROGreenButton>
                    )}
                    <ROOrangeButton
                        onClick={() => {
                            handleEditRoleClose('CANCEL');
                        }}
                    >
                        {t('Common.Cancel')}
                    </ROOrangeButton>
                </DialogActions>
            }
        >
            <Grid
                container
                spacing={'10px'}
                style={{ paddingTop: '10px' }}
            >
                <Grid
                    item
                    xs={8}
                >
                    <RODialogLabel>{t('AccountManagement.Roles.Form.RoleName')} *</RODialogLabel>
                    <TextBox
                        name='RoleName'
                        inputAttr={{ autocomplete: 'new-role' }}
                        defaultValue={roleTarget.RoleName}
                        onValueChanged={(e) => {
                            updateRoleForm('RoleName', e.value);
                        }}
                    />
                </Grid>
                {roleMode === 'New' && (
                    <Grid
                        item
                        xs={4}
                    >
                        <RODialogLabel>{t('AccountManagement.Roles.Form.CopyFrom')}</RODialogLabel>
                        <SelectBox
                            dataSource={assignableRoles}
                            displayExpr='text'
                            valueExpr='id'
                            onValueChanged={async (e) => {
                                loadSavedFunctionsRolesGroups(e.value);
                            }}
                        />
                    </Grid>
                )}
                <Grid
                    item
                    xs={12}
                >
                    <RODialogLabel>{t('AccountManagement.Roles.Form.RoleDescription')}</RODialogLabel>
                    <TextArea
                        name='RoleDescription'
                        height={60}
                        defaultValue={roleTarget.RoleDescription}
                        onValueChanged={(e) => {
                            updateRoleForm('RoleDescription', e.value);
                        }}
                    />
                </Grid>
                <Grid
                    item
                    xs={4}
                >
                    <div style={{ display: 'none' }}>
                        <input
                            id='SelFunctionSelectEvent'
                            type='text'
                            ref={refSelFunctionSelectEvent}
                        />
                    </div>
                    <TreeList
                        dataSource={assignableFunctions}
                        height={400}
                        showBorders={true}
                        showColumnLines={false}
                        columnAutoWidth={true}
                        wordWrapEnabled={true}
                        defaultExpandedRowKeys={expandedFunctionKeys}
                        selectedRowKeys={selFunctionKeys}
                        keyExpr='id'
                        parentIdExpr='parent'
                        id='functions'
                    >
                        <TlSearchPanel
                            visible={true}
                            placeholder={t('Common.Search')}
                        />
                        <TlHeaderFilter visible={false} />
                        <TlColumn
                            dataField='id'
                            caption=''
                            width='55px'
                            allowSorting={false}
                            allowFiltering={false}
                            visibleIndex='0'
                            headerCellRender={(e) => {
                                return (
                                    <CheckBox
                                        value={getFunctionListSelectAllValue()}
                                        onValueChanged={(e) => {
                                            if (e.event) {
                                                if (refSelFunctionSelectEvent && refSelFunctionSelectEvent.current.value !== 'RowClick') {
                                                    let selKeys = [];
                                                    if (e.value) {
                                                        selKeys = assignableFunctions.map((it) => it.id);
                                                    } else {
                                                        selKeys = [1];
                                                    }
                                                    setSelFunctionKeys(selKeys);
                                                    checkAccountManagementAccess(selKeys);
                                                } else {
                                                    refSelFunctionSelectEvent.current.value = 'AllClick';
                                                }
                                            } else {
                                                refSelFunctionSelectEvent.current.value = 'AllClick';
                                            }
                                        }}
                                    />
                                );
                            }}
                            cellRender={(e) => {
                                return (
                                    <div
                                        style={{ align: 'left' }}
                                        onClick={() => {
                                            handleFunctionRowClick(e);
                                        }}
                                    >
                                        <CheckBox value={selFunctionKeys.includes(e.data.id)} />
                                    </div>
                                );
                            }}
                        />
                        <TlColumn
                            dataField='text'
                            caption={t('AccountManagement.Roles.Form.Functions')}
                            cellRender={(e) => {
                                return (
                                    <div
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            handleFunctionRowClick(e);
                                        }}
                                    >
                                        {e.data.parent !== 0 && <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{e.data.text}</>}
                                        {e.data.parent === 0 && <>&nbsp;&nbsp;&nbsp;{e.data.text}</>}
                                    </div>
                                );
                            }}
                        ></TlColumn>
                    </TreeList>
                </Grid>
                <Grid
                    item
                    xs={4}
                >
                    <div style={{ display: 'none' }}>
                        <input
                            id='SelRoleSelectEvent'
                            type='text'
                            ref={refSelRoleSelectEvent}
                        />
                    </div>
                    <TreeList
                        dataSource={assignableRoles}
                        height={400}
                        showBorders={true}
                        showColumnLines={false}
                        columnAutoWidth={true}
                        wordWrapEnabled={true}
                        selectedRowKeys={selRoleKeys}
                        keyExpr='id'
                        id='roles'
                        ref={refRoleList}
                        onRowClick={(e) => {
                            if (hasAccountManagementAccess) {
                                let selKeys = [...selRoleKeys];
                                if (selKeys.includes(e.key)) {
                                    selKeys = selKeys.filter((item) => item != e.key);
                                } else {
                                    selKeys.push(e.key);
                                }
                                refSelRoleSelectEvent.current.value = 'RowClick';
                                setSelRoleKeys(selKeys);
                            }
                        }}
                    >
                        <TlSearchPanel
                            visible={true}
                            placeholder={t('Common.Search')}
                        />
                        <TlHeaderFilter visible={false} />
                        <TlColumn
                            dataField='id'
                            caption=''
                            width='0px'
                            allowSorting={false}
                            allowFiltering={false}
                            visibleIndex='0'
                            cellRender={() => {
                                return <div></div>;
                            }}
                        />
                        <TlColumn
                            dataField='role'
                            caption=''
                            width='30px'
                            allowSorting={false}
                            allowFiltering={false}
                            visibleIndex='0'
                            headerCellRender={(e) => {
                                return (
                                    <CheckBox
                                        disabled={!hasAccountManagementAccess}
                                        value={getRoleListSelectAllValue()}
                                        onValueChanged={(e) => {
                                            if (refSelRoleSelectEvent && refSelRoleSelectEvent.current.value !== 'RowClick') {
                                                let selKeys = [];
                                                if (e.value) {
                                                    selKeys = assignableRoles.map((it) => it.id);
                                                } else {
                                                    selKeys = [];
                                                }
                                                setSelRoleKeys(selKeys);
                                            } else {
                                                refSelRoleSelectEvent.current.value = 'AllClick';
                                            }
                                        }}
                                    />
                                );
                            }}
                            cellRender={(evt) => {
                                return (
                                    <div style={{ align: 'left' }}>
                                        <CheckBox
                                            disabled={!hasAccountManagementAccess}
                                            value={selRoleKeys.includes(evt.data.id)}
                                        />
                                    </div>
                                );
                            }}
                        />
                        <TlColumn
                            dataField='text'
                            caption={t('AccountManagement.Roles.Form.Roles')}
                            cellRender={(e) => {
                                return (
                                    <div
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            if (hasAccountManagementAccess) {
                                                let selKeys = [...selRoleKeys];
                                                if (selKeys.includes(e.data.id)) {
                                                    selKeys = selKeys.filter((item) => item != e.data.id);
                                                } else {
                                                    selKeys.push(e.data.id);
                                                }
                                                setSelRoleKeys(selKeys);
                                            }
                                        }}
                                    >
                                        {e.data.text}
                                    </div>
                                );
                            }}
                        ></TlColumn>
                    </TreeList>
                </Grid>
                <Grid
                    item
                    xs={4}
                >
                    <div style={{ display: 'none' }}>
                        <input
                            id='SelGroupSelectEvent'
                            type='text'
                            ref={refSelGroupSelectEvent}
                        />
                    </div>
                    <TreeList
                        dataSource={assignableGroups}
                        height={400}
                        showBorders={true}
                        showColumnLines={false}
                        columnAutoWidth={true}
                        wordWrapEnabled={true}
                        defaultSelectedRowKeys={selGroupKeys}
                        keyExpr='id'
                        id='groups'
                        ref={refGroupList}
                        onRowClick={(e) => {
                            if (hasAccountManagementAccess) {
                                let selKeys = [...selGroupKeys];
                                if (selKeys.includes(e.key)) {
                                    selKeys = selKeys.filter((item) => item != e.key);
                                } else {
                                    selKeys.push(e.key);
                                }
                                refSelGroupSelectEvent.current.value = 'RowClick';
                                setSelGroupKeys(selKeys);
                            }
                        }}
                    >
                        <TlSearchPanel
                            visible={true}
                            placeholder={t('Common.Search')}
                        />
                        <TlHeaderFilter visible={false} />
                        <TlColumn
                            dataField='id'
                            caption=''
                            width='0px'
                            allowSorting={false}
                            allowFiltering={false}
                            visibleIndex='0'
                            cellRender={() => {
                                return <div></div>;
                            }}
                        />
                        <TlColumn
                            dataField='group'
                            caption=''
                            width='30px'
                            allowSorting={false}
                            allowFiltering={false}
                            visibleIndex='0'
                            headerCellRender={(e) => {
                                return (
                                    <CheckBox
                                        disabled={!hasAccountManagementAccess}
                                        value={getGroupListSelectAllValue()}
                                        onValueChanged={(e) => {
                                            if (refSelGroupSelectEvent && refSelGroupSelectEvent.current.value !== 'RowClick') {
                                                let selKeys = [];
                                                if (e.value) {
                                                    selKeys = assignableGroups.map((it) => it.id);
                                                } else {
                                                    selKeys = [];
                                                }
                                                setSelGroupKeys(selKeys);
                                            } else {
                                                refSelGroupSelectEvent.current.value = 'AllClick';
                                            }
                                        }}
                                    />
                                );
                            }}
                            cellRender={(evt) => {
                                return (
                                    <div style={{ align: 'left' }}>
                                        <CheckBox
                                            disabled={!hasAccountManagementAccess}
                                            value={selGroupKeys.includes(evt.data.id)}
                                        />
                                    </div>
                                );
                            }}
                        />
                        <TlColumn
                            dataField='text'
                            caption={t('AccountManagement.Roles.Form.Groups')}
                            visibleIndex='1'
                            cellRender={(e) => {
                                return (
                                    <div
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            if (hasAccountManagementAccess) {
                                                let selKeys = [...selGroupKeys];
                                                if (selKeys.includes(e.data.id)) {
                                                    selKeys = selKeys.filter((item) => item != e.data.id);
                                                } else {
                                                    selKeys.push(e.data.id);
                                                }
                                                setSelGroupKeys(selKeys);
                                            }
                                        }}
                                    >
                                        {e.data.text}
                                    </div>
                                );
                            }}
                        ></TlColumn>
                    </TreeList>
                </Grid>
            </Grid>
        </RODialog>
    );
};
