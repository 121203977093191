import React from 'react';
import {
    Grid,
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
    Switch,
    FormControlLabel,
    Tabs,
    Tab,
    Box,
    OutlinedInput,
} from '@mui/material';
import { postRouteSet, getRouteSets, putRouteSet, deleteRouteSet, getDrivers, getFacilities, getTrucks } from '../../_actions/Manage.actions';
import MaterialReactTable from 'material-react-table';
import Select from 'react-select';
import { updateUserActivity } from '../../_actions/Global.actions';
import CreatableSelect from 'react-select/creatable';
import {
    routeTimeOptions,
    routeTripOptions,
    timeOfDayOptions,
    breakStartsOptions,
    breakEndsOptions,
    breakDurationOptions,
    deFaultServeTimeOptions,
    plannerRouteTimeOptions,
} from '../../_helpers/common';
import ReactSelect from 'react-select';
import { DeleteDialogue } from './DeleteDialogue';
import { getPreOrPostTripDuration } from '../../_helpers/date';
import { useTranslation } from 'react-i18next';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

const AddNewRouteButton = (props) => {
    const { handleNewRouteSet } = props;
    const { t } = useTranslation();
    return (
        <IconButton
            title={t('RoutePlans.RouteSet.Actions.New')}
            color='primary'
            style={{ position: 'absolute', top: '10px', right: '50px', padding: 0, color: '#16a8a6' }}
            onClick={() => handleNewRouteSet()}
        >
            <i className='RS_ADD' />
        </IconButton>
    );
};

const RouteMethodAndZones = (props) => {
    const {
        methodOptions,
        updateOptimizeRouteNumber,
        saveOptimizeRouteNumber,
        GenerateMethod,
        enableNeighborhood,
        routePlanId,
        updateRouteOptimizer,
        updateNeighborhoodCluster,
        NumRoutes,
        d,
    } = props;

    const { t } = useTranslation();

    return (
        <Grid
            container
            alignItems={'end'}
        >
            <Grid
                item
                xs={3}
            />
            <Grid
                item
                xs={1}
                style={{ maxWidth: '80px' }}
            >
                <span style={{ fontSize: 12, marginLeft: '20px' }}>{t('RoutePlans.RouteSet.Labels.Method')}</span>
            </Grid>
            <Grid
                item
                xs={2}
                style={{ maxWidth: '110px' }}
            >
                <ReactSelect
                    className='basic-single'
                    classNamePrefix='select'
                    isClearable={false}
                    isSearchable={false}
                    value={
                        d.RouteCount < 1
                            ? methodOptions.slice(0, -1).find((s) => s.value === GenerateMethod)
                            : methodOptions.find((s) => s.value === GenerateMethod)
                    }
                    onChange={(e) => updateRouteOptimizer(routePlanId, e)}
                    options={d.RouteCount < 1 ? methodOptions.slice(0, -1) : methodOptions}
                    menuPortalTarget={document.body}
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            minHeight: '28px',
                        }),
                        dropdownIndicator: (base) => ({ ...base, padding: 0 }),
                        option: (provided, state) => ({
                            ...provided,
                            padding: 5,
                        }),
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                />
            </Grid>
            {GenerateMethod === 'Number' && (
                <Grid
                    item
                    xs={1}
                    style={{ paddingLeft: '5px', maxWidth: '75px' }}
                >
                    <OutlinedInput
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', style: { padding: 2 } }}
                        onChange={updateOptimizeRouteNumber}
                        onBlur={saveOptimizeRouteNumber}
                        value={NumRoutes}
                        placeholder={t('Terms.SolverMethods.Number')}
                    />
                </Grid>
            )}
            <Grid
                item
                xs={2}
                style={{ paddingLeft: '5px' }}
            >
                <FormControlLabel
                    style={{ height: '25px', marginTop: '8px' }}
                    disabled={GenerateMethod === 'Number' || d.RouteCount < 1}
                    checked={enableNeighborhood === 'Off' || d.RouteCount < 1 ? false : true}
                    control={<Switch color='primary' />}
                    label={<span style={{ fontSize: 12 }}>{t('RoutePlans.RouteSet.Labels.Zones')}</span>}
                    labelPlacement='start'
                    onChange={(e) => updateNeighborhoodCluster(routePlanId, e.target.checked)}
                />
            </Grid>
        </Grid>
    );
};

const EditRouteSetDialogue = (props) => {
    const {
        siteMode,
        editRouteSetDialog,
        mode,
        title,
        tab,
        handleTabChange,
        routeNameError,
        errorMsg,
        target,
        validateRouteName,
        defaultName,
        updateRoutePlan,
        driverOptions,
        truckOptions,
        facilityOptions,
        updateServeTimeMult,
        serveTimeOptions,
        handleCreateServeTimeMult,
        intermediateFacility,
        updateIntermediateTripSelect,
        intermediateFacilityTripOptions,
        validForm,
        updateIntermediateFacilitySelect,
        lastTripFacility,
        updateLastTripFacilitySelect,
        trips,
        breaks,
        updateBreakEarliest,
        updateBreakLatest,
        handleEditClose,
        editingName,
        addTrip,
        deleteBreak,
        deleteTrip,
        addBreak,
        updateTrip,
        sortTrip,
        updateBreakDuration,
    } = props;

    const { t } = useTranslation();

    return (
        <Dialog
            open={editRouteSetDialog}
            maxWidth={'800px'}
        >
            <DialogTitle>
                {t(`RoutePlans.RouteSet.Actions.${mode}`)} {t(`RoutePlans.RouteSet.Title`)}
            </DialogTitle>
            <DialogContent>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            value={tab}
                            onChange={handleTabChange}
                        >
                            <Tab label={t('RoutePlans.RouteSet.Form.Route')} />
                            <Tab label={t('RoutePlans.RouteSet.Form.IntermediateTrips')} />
                            <Tab label={t('RoutePlans.RouteSet.Form.Breaks')} />
                        </Tabs>
                    </Box>
                    <TabPanel
                        value={tab}
                        index={0}
                    >
                        <Grid
                            container
                            spacing={1}
                        >
                            <Grid
                                item
                                xs={12}
                            >
                                {mode === 'New' || mode === 'Copy' ? (
                                    <TextField
                                        autoFocus
                                        margin='dense'
                                        id='Route'
                                        name='Route'
                                        label={t('RoutePlans.RouteSet.Form.RouteName')}
                                        type='text'
                                        error={routeNameError}
                                        helperText={errorMsg}
                                        fullWidth
                                        variant='standard'
                                        value={target.Route}
                                        onChange={validateRouteName}
                                        inputProps={{ maxLength: 10 }}
                                    />
                                ) : (
                                    <TextField
                                        disabled={mode === 'Edit' && editingName === defaultName}
                                        autoFocus
                                        margin='dense'
                                        id='Route'
                                        name='Route'
                                        label={t('RoutePlans.RouteSet.Form.RouteName')}
                                        type='text'
                                        fullWidth
                                        error={routeNameError}
                                        helperText={errorMsg}
                                        variant='standard'
                                        value={target.Route}
                                        onChange={validateRouteName}
                                        inputProps={{ maxLength: 10 }}
                                    />
                                )}
                            </Grid>
                        </Grid>
                        <br />
                        <Grid
                            container
                            spacing={1}
                        >
                            <Grid
                                item
                                xs={12}
                            >
                                <Typography
                                    variant='body1'
                                    gutterBottom
                                    style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px' }}
                                >
                                    {t('RoutePlans.RouteSet.Form.Driver')}
                                </Typography>
                                <Select
                                    className='basic-single'
                                    classNamePrefix='select'
                                    isSearchable={true}
                                    name='DriverId'
                                    id='DriverId'
                                    value={{ value: target.DriverId, label: target.DriverName }}
                                    onChange={(e) => updateRoutePlan(e, 'DriverId', 'DriverName')}
                                    options={driverOptions}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <br />
                        <Grid
                            container
                            spacing={1}
                        >
                            <Grid
                                item
                                xs={12}
                            >
                                <Typography
                                    variant='body1'
                                    gutterBottom
                                    style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px' }}
                                >
                                    {t('RoutePlans.RouteSet.Form.Truck')}
                                </Typography>
                                <Select
                                    className='basic-single'
                                    classNamePrefix='select'
                                    isSearchable={true}
                                    name='TruckId'
                                    id='TruckId'
                                    value={{ value: target.TruckId, label: target.TruckName }}
                                    onChange={(e) => updateRoutePlan(e, 'TruckId', 'TruckName')}
                                    options={truckOptions}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <br />
                        <Grid
                            container
                            spacing={1}
                        >
                            <Grid
                                item
                                xs={12}
                            >
                                <Typography
                                    variant='body1'
                                    gutterBottom
                                    style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px' }}
                                >
                                    {t('RoutePlans.RouteSet.Form.StartingFacility')}
                                </Typography>
                                <Select
                                    className='basic-single'
                                    classNamePrefix='select'
                                    isSearchable={true}
                                    name='StartLocation'
                                    id='StartLocation'
                                    value={{ value: target.StartLocation, label: target.StartLocationName }}
                                    onChange={(e) => updateRoutePlan(e, 'StartLocation', 'StartLocationName')}
                                    options={facilityOptions}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <br />
                        <Grid
                            container
                            spacing={1}
                        >
                            <Grid
                                item
                                xs={6}
                            >
                                <Typography
                                    gutterBottom
                                    style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px' }}
                                >
                                    {t('RoutePlans.RouteSet.Form.RouteTime')}
                                </Typography>
                                <Select
                                    className='basic-single'
                                    classNamePrefix='select'
                                    isClearable={false}
                                    isSearchable={true}
                                    name='TargetTime'
                                    value={
                                        siteMode === 'DISPATCHER'
                                            ? routeTimeOptions.find((o) => o.value === target.TargetTime)
                                            : plannerRouteTimeOptions.find((o) => o.value === target.TargetTime)
                                    }
                                    onChange={(e) => updateRoutePlan(e, 'TargetTime')}
                                    options={siteMode === 'DISPATCHER' ? routeTimeOptions : plannerRouteTimeOptions}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={6}
                            >
                                <Typography
                                    gutterBottom
                                    style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px' }}
                                >
                                    {t('RoutePlans.RouteSet.Form.DepartsAt')}
                                </Typography>
                                <Select
                                    className='basic-single'
                                    classNamePrefix='select'
                                    isClearable={false}
                                    isSearchable={true}
                                    name='StartTime'
                                    value={timeOfDayOptions.find((o) => o.value === target.StartTime)}
                                    onChange={(e) => updateRoutePlan(e, 'StartTime')}
                                    options={timeOfDayOptions}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <br />
                        <Grid
                            container
                            spacing={1}
                        >
                            <Grid
                                item
                                xs={6}
                            >
                                <Typography
                                    gutterBottom
                                    style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px' }}
                                >
                                    {t('RoutePlans.RouteSet.Form.PreTrip')}
                                </Typography>
                                <Select
                                    className='basic-single'
                                    classNamePrefix='select'
                                    isClearable={false}
                                    isSearchable={true}
                                    name='PreStartTime'
                                    value={routeTripOptions.find((o) => {
                                        let PreStartTime = '';
                                        if (target && target.PreStartTime && target.PreStartTime.includes(':')) {
                                            const pst = target.PreStartTime.replace(/"/g, '').split(':');
                                            PreStartTime = pst[0] * 60 * 60 + pst[1] * 60;
                                        } else if (target && target.PreStartTime) {
                                            PreStartTime = target.PreStartTime;
                                        }
                                        return o.value == PreStartTime;
                                    })}
                                    onChange={(e) => updateRoutePlan(e, 'PreStartTime')}
                                    options={routeTripOptions}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={6}
                            >
                                <Typography
                                    gutterBottom
                                    style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px' }}
                                >
                                    {t('RoutePlans.RouteSet.Form.PostTrip')}
                                </Typography>
                                <Select
                                    className='basic-single'
                                    classNamePrefix='select'
                                    isClearable={false}
                                    isSearchable={true}
                                    name='PostEndTime'
                                    value={routeTripOptions.find((o) => {
                                        let PostEndTime = '';
                                        if (target && target.PostEndTime && target.PostEndTime.includes(':')) {
                                            const pet = target.PostEndTime.replace(/"/g, '').split(':');
                                            PostEndTime = pet[0] * 60 * 60 + pet[1] * 60;
                                        } else if (target && target.PostEndTime) {
                                            PostEndTime = target.PostEndTime;
                                        }
                                        return o.value == PostEndTime;
                                    })}
                                    onChange={(e) => updateRoutePlan(e, 'PostEndTime')}
                                    options={routeTripOptions}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <br />
                        <Grid
                            container
                            spacing={1}
                        >
                            <Grid
                                item
                                xs={12}
                            >
                                <Typography
                                    gutterBottom
                                    style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px' }}
                                >
                                    {t('RoutePlans.RouteSet.Form.ScaleServiceTime')}
                                </Typography>
                                <CreatableSelect
                                    className='basic-single'
                                    classNamePrefix='select'
                                    isSearchable={true}
                                    name='ServeTimeMult'
                                    id='ServeTimeMult'
                                    value={
                                        target && target.ServeTimeMult
                                            ? {
                                                  label: Math.round(Number(target?.ServeTimeMult) * 100) + '%',
                                                  value: Number(target?.ServeTimeMult).toFixed(2),
                                              }
                                            : { label: '', value: '' }
                                    }
                                    onChange={updateServeTimeMult}
                                    options={serveTimeOptions}
                                    onCreateOption={(e) => handleCreateServeTimeMult(e)}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel
                        value={tab}
                        index={1}
                    >
                        <Grid
                            container
                            spacing={1}
                        >
                            <Grid
                                item
                                xs={11}
                            >
                                <Typography
                                    gutterBottom
                                    style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px' }}
                                >
                                    {t('RoutePlans.RouteSet.Form.DefaultIntermediateFacility')}
                                </Typography>
                                <Select
                                    className='basic-single'
                                    classNamePrefix='select'
                                    isSearchable={true}
                                    name='IntermediateFacility'
                                    id='IntermediateFacility'
                                    value={{ value: intermediateFacility.FacilityId, label: intermediateFacility.Label }}
                                    onChange={updateIntermediateFacilitySelect}
                                    options={intermediateFacilityTripOptions}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={1}
                            />
                        </Grid>
                        <br />
                        {(mode !== 'Edit' || target.Route !== defaultName) && (
                            <Grid
                                container
                                spacing={2}
                            >
                                <table
                                    style={{ width: '100%' }}
                                    className={'formTable'}
                                >
                                    <thead>
                                        <tr>
                                            <th style={{ width: '120px' }}>
                                                <Typography
                                                    variant='body1'
                                                    gutterBottom
                                                    style={{ marginBottom: 0, color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px' }}
                                                >
                                                    {t('RoutePlans.RouteSet.Form.Trip')}
                                                </Typography>
                                            </th>
                                            <th style={{ width: '180px' }}>
                                                <Typography
                                                    variant='body1'
                                                    gutterBottom
                                                    style={{ marginBottom: 0, color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px' }}
                                                >
                                                    {t('RoutePlans.RouteSet.Form.Facility')}
                                                </Typography>
                                            </th>
                                            <th style={{ width: '50px' }}>
                                                <IconButton
                                                    title={t('Common.New')}
                                                    color='primary'
                                                    className={'icon_button'}
                                                    style={{ padding: 0, backgroundColor: '#fff', color: '#16a8a6' }}
                                                    onClick={() => addTrip()}
                                                >
                                                    <i className='RS_ADD' />
                                                </IconButton>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {trips.map((trip) => {
                                            return (
                                                <tr style={{ height: '40px' }}>
                                                    <td style={{ paddingTop: 0, paddingLeft: '10px', textAlign: 'center', height: '40px' }}>
                                                        <TextField
                                                            margin='dense'
                                                            id='TripNum'
                                                            name='TripNum'
                                                            type='number'
                                                            fullWidth
                                                            variant='standard'
                                                            value={trip.TripNum}
                                                            onChange={(e) => updateTrip(e, trip)}
                                                            onBlur={() => sortTrip()}
                                                            className={'center_input'}
                                                            style={{ paddingLeft: '5px', width: '75px' }}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Select
                                                            className='basic-single'
                                                            classNamePrefix='select'
                                                            isSearchable={true}
                                                            name='IntermediateTrips'
                                                            id='IntermediateTrips'
                                                            value={{ value: trip.FacilityId, label: trip.Label }}
                                                            onChange={(e) => updateIntermediateTripSelect(e, trip)}
                                                            options={intermediateFacilityTripOptions}
                                                            menuPortalTarget={document.body}
                                                            styles={{
                                                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                                height: '40px',
                                                                width: '180px',
                                                            }}
                                                        />
                                                    </td>
                                                    <td>
                                                        <IconButton
                                                            title={t('Common.Delete')}
                                                            color='primary'
                                                            className={'icon_button'}
                                                            style={{
                                                                padding: 0,
                                                                marginTop: '0',
                                                                marginLeft: '7px',
                                                                backgroundColor: '#fff',
                                                                color: '#16a8a6',
                                                            }}
                                                            onClick={() => deleteTrip(trip)}
                                                        >
                                                            <i className='RS_DELETE' />
                                                        </IconButton>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </Grid>
                        )}
                        <Grid
                            container
                            spacing={1}
                        >
                            <Grid
                                item
                                xs={11}
                            >
                                <Typography
                                    variant='body1'
                                    gutterBottom
                                    style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px' }}
                                >
                                    Last Trip Facility
                                    {t('RoutePlans.RouteSet.Form.LastTripFacility')}
                                </Typography>
                                <Select
                                    className='basic-single'
                                    classNamePrefix='select'
                                    isSearchable={true}
                                    name='LastTripFacility'
                                    id='LastTripFacility'
                                    value={{ value: lastTripFacility.FacilityId, label: lastTripFacility.Label }}
                                    onChange={updateLastTripFacilitySelect}
                                    options={intermediateFacilityTripOptions}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={1}
                            />
                        </Grid>
                    </TabPanel>
                    <TabPanel
                        value={tab}
                        index={2}
                    >
                        <table
                            style={{ width: '100%' }}
                            className={'formTable'}
                        >
                            <thead>
                                <tr>
                                    <th
                                        style={{ width: '250px' }}
                                        colSpan={3}
                                    >
                                        <Typography
                                            variant='body1'
                                            gutterBottom
                                            style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px' }}
                                        >
                                            {t('RoutePlans.RouteSet.Form.Break')}
                                        </Typography>
                                    </th>
                                    <th style={{ width: '150px' }}>
                                        <Typography
                                            variant='body1'
                                            gutterBottom
                                            style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px' }}
                                        >
                                            {t('RoutePlans.RouteSet.Form.Duration')}
                                        </Typography>
                                    </th>
                                    <th style={{ width: '50px' }}>
                                        <IconButton
                                            title={t('Common.New')}
                                            color='primary'
                                            className={'icon_button'}
                                            style={{ backgroundColor: '#fff', color: '#16a8a6' }}
                                            onClick={() => addBreak()}
                                        >
                                            <i className='RS_ADD' />
                                        </IconButton>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {breaks.map((b, i) => {
                                    return (
                                        <tr style={i === 0 ? { height: '75px' } : {}}>
                                            <td>
                                                {i === 0 && (
                                                    <Typography
                                                        gutterBottom
                                                        style={{ textAlign: 'center', color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px' }}
                                                    >
                                                        {t('RoutePlans.RouteSet.Form.After')}
                                                    </Typography>
                                                )}
                                                <Select
                                                    className='basic-single'
                                                    classNamePrefix='select'
                                                    isClearable={false}
                                                    isSearchable={true}
                                                    name='Earliest'
                                                    value={breakStartsOptions.find((o) => o.value === b.Earliest)}
                                                    onChange={(e) => updateBreakEarliest(e, b)}
                                                    options={breakStartsOptions}
                                                    menuPortalTarget={document.body}
                                                    styles={{
                                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                    }}
                                                />
                                            </td>
                                            <td>-</td>
                                            <td>
                                                {i === 0 && (
                                                    <Typography
                                                        gutterBottom
                                                        style={{ textAlign: 'center', color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px' }}
                                                    >
                                                        {t('RoutePlans.RouteSet.Form.Before')}
                                                    </Typography>
                                                )}
                                                <Select
                                                    className='basic-single'
                                                    classNamePrefix='select'
                                                    isClearable={false}
                                                    isSearchable={true}
                                                    name='Latest'
                                                    value={breakEndsOptions.find((o) => o.value === b.Latest)}
                                                    onChange={(e) => updateBreakLatest(e, b)}
                                                    options={breakEndsOptions}
                                                    menuPortalTarget={document.body}
                                                    styles={{
                                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                {i === 0 && (
                                                    <Typography
                                                        gutterBottom
                                                        style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px' }}
                                                    >
                                                        &nbsp;
                                                    </Typography>
                                                )}
                                                <Select
                                                    className='basic-single'
                                                    classNamePrefix='select'
                                                    isClearable={false}
                                                    isSearchable={true}
                                                    name='Duration'
                                                    value={breakDurationOptions.find((o) => o.value == b.Duration)}
                                                    onChange={(e) => updateBreakDuration(e, b)}
                                                    options={breakDurationOptions}
                                                    menuPortalTarget={document.body}
                                                    styles={{
                                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <IconButton
                                                    title={t('Common.Delete')}
                                                    color='primary'
                                                    className={'icon_button'}
                                                    style={{ backgroundColor: '#fff', color: '#16a8a6' }}
                                                    onClick={() => deleteBreak(b)}
                                                >
                                                    <i className='RS_DELETE' />
                                                </IconButton>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </TabPanel>
                </Box>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                    style={{ backgroundColor: '#ee8a1d', color: '#fff' }}
                    onClick={() => handleEditClose('CANCEL')}
                >
                    {t('Common.Cancel')}
                </Button>
                {validForm() && !routeNameError && (
                    <Button
                        style={{ backgroundColor: '#16a8a6', color: '#fff' }}
                        onClick={() => handleEditClose('SAVE')}
                    >
                        {t('Common.Save')}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

const RouteSetTable = (props) => {
    const { t } = useTranslation();
    const title = t('RoutePlans.RouteSet.Title');
    const { workspace, siteMode, project, d, refreshRouteSet, setRefreshRouteSet } = props;
    const [data, setData] = React.useState([]);
    const [editRouteSetDialog, setEditRouteSetDialog] = React.useState(false);
    const [deleteRouteSetDialog, setDeleteRouteSetDialog] = React.useState(false);
    const [mode, setMode] = React.useState('New');
    const [target, setTarget] = React.useState({});
    const [tab, setTab] = React.useState(0);
    const [driverOptions, setDriverOptions] = React.useState({});
    const [truckOptions, setTruckOptions] = React.useState({});
    const [facilityOptions, setFacilityOptions] = React.useState({});

    const [intermediateFacilityTripOptions, setIntermediateFacilityTripOptions] = React.useState({});
    const [intermediateFacility, setIntermediateFacility] = React.useState({});
    const [lastTripFacility, setLastTripFacility] = React.useState({});
    const [trips, setTrips] = React.useState([]);
    const [breaks, setBreaks] = React.useState([]);
    const [routeNameError, setRouteNameError] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState('');
    const [editingName, setEditingName] = React.useState('');

    const defaultName = 'Default';

    const [serveTimeOptions, setServeTimeOptions] = React.useState(deFaultServeTimeOptions);

    const methodOptions = [
        { label: t('Terms.SolverMethods.Time'), value: 'Time' },
        { label: t('Terms.SolverMethods.Number'), value: 'Number' },
        { label: t('Terms.SolverMethods.FullFleet'), value: 'Full' },
    ];

    const routeSetCustomSort = (a, b, c) => {
        if (b && b.original && b.original['Route'] === defaultName) {
            return 1;
        } else if (a && a.original && a.original['Route'] === defaultName) {
            return -1;
        } else {
            if (isNaN(a.original[c]) || isNaN(b.original[c])) {
                return a.original[c].localeCompare(b.original[c], 'en', { numeric: true });
            } else {
                return a.original[c] - b.original[c];
            }
        }
    };

    let columns = [
        {
            header: t('RoutePlans.RouteSet.Columns.Route'),
            accessorKey: 'Route',
            Header: ({ column }) => (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <span
                        className='RT_ROUTE'
                        style={{ fontSize: '22px' }}
                    ></span>
                    {column.columnDef.header}
                </div>
            ),
            size: 50,
            sortingFn: (a, b, c) => routeSetCustomSort(a, b, c),
            muiTableHeadCellProps: { align: 'center', sx: { fontSize: '11px' } },
            muiTableBodyCellProps: { sx: { fontSize: '11px', borderBottom: 'none' } },
            Cell: ({ cell, row }) => {
                if (cell.getValue() === defaultName) {
                    return (
                        <b>
                            <i>{cell.getValue()}</i>
                        </b>
                    );
                } else {
                    return cell.getValue();
                }
            },
        },
        {
            header: t('RoutePlans.RouteSet.Columns.Driver'),
            Header: ({ column }) => (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <span
                        className='DR_DRIVE'
                        style={{ fontSize: '22px' }}
                    ></span>
                    {column.columnDef.header}
                </div>
            ),
            accessorKey: 'DriverName',
            size: 50,
            muiTableHeadCellProps: { align: 'center', sx: { fontSize: '11px' } },
            muiTableBodyCellProps: ({ cell, table }) => ({
                align: 'center',
                sx: { fontSize: '11px', borderBottom: 'none', padding: '0 0.5rem' },
            }),
            sortingFn: (a, b, c) => routeSetCustomSort(a, b, c),
            Cell: ({ cell, row }) => {
                if (row.original.Route === defaultName) {
                    return (
                        <b>
                            <i> {cell.getValue()}</i>
                        </b>
                    );
                } else {
                    return cell.getValue();
                }
            },
        },
        {
            header: t('RoutePlans.RouteSet.Columns.Truck'),
            Header: ({ column }) => (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <span
                        className='TR_TRUCK'
                        style={{ fontSize: '22px' }}
                    ></span>
                    {column.columnDef.header}
                </div>
            ),
            accessorKey: 'TruckName',
            size: 50,
            muiTableHeadCellProps: { align: 'center', sx: { fontSize: '11px' } },
            muiTableBodyCellProps: ({ cell, table }) => ({
                align: 'center',
                sx: { fontSize: '11px', borderBottom: 'none', padding: '0 0.5rem' },
            }),
            sortingFn: (a, b, c) => routeSetCustomSort(a, b, c),
            Cell: ({ cell, row }) => {
                if (row.original.Route === defaultName) {
                    return (
                        <b>
                            <i> {cell.getValue()}</i>
                        </b>
                    );
                } else {
                    return cell.getValue();
                }
            },
        },
        {
            header: t('RoutePlans.RouteSet.Columns.RouteTime'),
            Header: ({ column }) => (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <span
                        className='RT_ROUTE_TIME'
                        style={{ fontSize: '22px' }}
                    ></span>
                    {column.columnDef.header}
                </div>
            ),
            accessorKey: 'TargetTime',
            size: 50,
            muiTableHeadCellProps: { align: 'center', sx: { fontSize: '11px' } },
            muiTableBodyCellProps: ({ cell, table }) => ({
                align: 'center',
                sx: { fontSize: '11px', borderBottom: 'none', padding: '0 0.5rem' },
            }),
            sortingFn: (a, b, c) => routeSetCustomSort(a, b, c),
            Cell: ({ cell, row }) => {
                if (row.original.Route === defaultName) {
                    return (
                        <b>
                            <i> {cell.getValue()}</i>
                        </b>
                    );
                } else {
                    return cell.getValue();
                }
            },
        },
        {
            header: t('RoutePlans.RouteSet.Columns.StartLocation'),
            Header: ({ column }) => (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <span
                        className='RT_START_LOCATION'
                        style={{ fontSize: '22px' }}
                    ></span>
                    {column.columnDef.header}
                </div>
            ),
            accessorKey: 'StartLocationName',
            size: 50,
            muiTableHeadCellProps: { align: 'center', sx: { fontSize: '11px' } },
            muiTableBodyCellProps: ({ cell, table }) => ({
                align: 'center',
                sx: { fontSize: '11px', borderBottom: 'none', padding: '0 0.5rem' },
            }),
            sortingFn: (a, b, c) => routeSetCustomSort(a, b, c),
            Cell: ({ cell, row }) => {
                if (row.original.Route === defaultName) {
                    return (
                        <b>
                            <i> {cell.getValue()}</i>
                        </b>
                    );
                } else {
                    return cell.getValue();
                }
            },
        },
        {
            header: t('RoutePlans.RouteSet.Columns.PreTrip'),
            Header: ({ column }) => (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <span
                        className='RT_PRE_TRIP'
                        style={{ fontSize: '22px' }}
                    ></span>
                    {column.columnDef.header}
                </div>
            ),
            accessorKey: 'PreStartTime',
            size: 50,
            muiTableHeadCellProps: { align: 'center', sx: { fontSize: '11px' } },
            muiTableBodyCellProps: ({ cell, table }) => ({
                align: 'center',
                sx: { fontSize: '11px', borderBottom: 'none', padding: '0 0.5rem' },
            }),
            sortingFn: (a, b, c) => routeSetCustomSort(a, b, c),
            Cell: ({ cell, row }) => {
                if (row.original.Route === defaultName) {
                    return (
                        <b>
                            <i> {cell.getValue()}</i>
                        </b>
                    );
                } else {
                    return cell.getValue();
                }
            },
        },
        {
            header: t('RoutePlans.RouteSet.Columns.DepartsAt'),
            Header: ({ column }) => (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <span
                        className='RT_DEPART'
                        style={{ fontSize: '22px' }}
                    ></span>
                    {column.columnDef.header}
                </div>
            ),
            accessorKey: 'StartTime',
            size: 40,
            muiTableHeadCellProps: { align: 'center', sx: { fontSize: '11px' } },
            muiTableBodyCellProps: ({ cell, table }) => ({
                align: 'center',
                sx: { fontSize: '11px', borderBottom: 'none', padding: '0 0.5rem' },
            }),
            sortingFn: (a, b, c) => routeSetCustomSort(a, b, c),
            Cell: ({ cell, row }) => {
                if (row.original.Route === defaultName) {
                    return (
                        <b>
                            <i> {cell.getValue()}</i>
                        </b>
                    );
                } else {
                    return cell.getValue();
                }
            },
        },
        {
            header: t('RoutePlans.RouteSet.Columns.PostTrip'),
            Header: ({ column }) => (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <span
                        className='RT_POST_TRIP'
                        style={{ fontSize: '22px' }}
                    ></span>
                    {column.columnDef.header}
                </div>
            ),
            accessorKey: 'PostEndTime',
            size: 40,
            muiTableHeadCellProps: { align: 'center', sx: { fontSize: '11px' } },
            muiTableBodyCellProps: ({ cell, table }) => ({
                align: 'center',
                sx: { fontSize: '11px', borderBottom: 'none', padding: '0 0.5rem' },
            }),
            sortingFn: (a, b, c) => routeSetCustomSort(a, b, c),
            Cell: ({ cell, row }) => {
                if (row.original.Route === defaultName) {
                    return (
                        <b>
                            <i> {cell.getValue()}</i>
                        </b>
                    );
                } else {
                    return cell.getValue();
                }
            },
        },
        {
            header: t('RoutePlans.RouteSet.Columns.Breaks'),
            Header: ({ column }) => (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <span
                        className='RT_BREAK'
                        style={{ fontSize: '22px' }}
                    ></span>
                    {column.columnDef.header}
                </div>
            ),
            accessorKey: 'Breaks',
            size: 40,
            muiTableHeadCellProps: { align: 'center', sx: { fontSize: '11px' } },
            muiTableBodyCellProps: ({ cell, table }) => ({
                align: 'center',
                sx: { fontSize: '11px', borderBottom: 'none', padding: '0 0.5rem' },
            }),
            sortingFn: (a, b, c) => routeSetCustomSort(a, b, c),
            Cell: ({ cell, row }) => {
                if (row.original.Route === defaultName) {
                    return (
                        <b>
                            <i>{cell.getValue() ? 'Yes' : 'No'}</i>
                        </b>
                    );
                } else {
                    return `${cell.getValue() ? 'Yes' : 'No'}`;
                }
            },
        },
        {
            header: t('RoutePlans.RouteSet.Columns.SpecificTrips'),
            Header: ({ column }) => (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <span
                        className='RT_TRIP'
                        style={{ fontSize: '22px' }}
                    ></span>
                    {column.columnDef.header}
                </div>
            ),
            accessorKey: 'Trips',
            size: 40,
            muiTableHeadCellProps: { align: 'center', sx: { fontSize: '11px' } },
            muiTableBodyCellProps: ({ cell, table }) => ({
                align: 'center',
                sx: { fontSize: '11px', borderBottom: 'none', padding: '0 0.5rem' },
            }),
            sortingFn: (a, b, c) => routeSetCustomSort(a, b, c),
            Cell: ({ cell, row }) => {
                if (row.original.Route === defaultName) {
                    return (
                        <b>
                            <i>{cell.getValue() ? 'Yes' : 'No'}</i>
                        </b>
                    );
                } else {
                    return `${cell.getValue() ? 'Yes' : 'No'}`;
                }
            },
        },
        {
            header: t('RoutePlans.RouteSet.Columns.ServiceTimeScale'),
            Header: ({ column }) => (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <span
                        className='RS_SERVICE_TIME_SCALE'
                        style={{ fontSize: '22px' }}
                    ></span>
                    <span style={{ whiteSpace: 'nowrap' }}>{column.columnDef.header}</span>
                </div>
            ),
            accessorKey: 'ServeTimeMult',
            size: 50,
            sortingFn: (a, b, c) => routeSetCustomSort(a, b, c),
            muiTableHeadCellProps: { align: 'center', sx: { fontSize: '11px' } },
            muiTableBodyCellProps: ({ cell, table }) => ({
                align: 'center',
                sx: { fontSize: '11px', borderBottom: 'none', padding: '0 0.5rem' },
            }),
            Cell: ({ cell, row }) => {
                if (row.original.Route === defaultName) {
                    if (cell.getValue() === 0) {
                        return (
                            <b>
                                <i></i>
                            </b>
                        );
                    } else {
                        return (
                            <b>
                                <i>{Math.round(cell.getValue() * 100) + '%'}</i>
                            </b>
                        );
                    }
                } else {
                    if (cell.getValue() === 0) {
                        return ``;
                    } else {
                        return `${Math.round(cell.getValue() * 100) + '%'}`;
                    }
                }
            },
        },
        {
            header: '',
            accessorKey: 'actions',
            enableSorting: false,
            muiTableHeadCellProps: { align: 'center', sx: { fontSize: '11px' } },
            muiTableBodyCellProps: { align: 'center', sx: { borderBottom: 'none', padding: '0' } },
            size: 40,
            Cell: ({ cell, row }) => {
                return row.original.Route === defaultName ? (
                    <div style={{ whiteSpace: 'nowrap' }}>
                        <IconButton
                            title={t('RoutePlans.RouteSet.Actions.Edit')}
                            color='primary'
                            className={'icon_button'}
                            style={{ color: '#16a8a6', marginLeft: '5px', padding: '0', fontSize: '18px' }}
                            onClick={() => handleEditRouteSet(row.original)}
                        >
                            <i className='RS_EDIT' />
                        </IconButton>
                    </div>
                ) : (
                    <div style={{ whiteSpace: 'nowrap' }}>
                        <IconButton
                            title={t('RoutePlans.RouteSet.Actions.Copy')}
                            color='primary'
                            className={'icon_button'}
                            style={{ color: '#16a8a6', marginLeft: '5px', padding: '0', fontSize: '18px' }}
                            onClick={() => handleCopyRouteSet(row.original)}
                        >
                            <i className='RS_COPY' />
                        </IconButton>
                        <IconButton
                            title={t('RoutePlans.RouteSet.Actions.Edit')}
                            color='primary'
                            className={'icon_button'}
                            style={{ color: '#16a8a6', marginLeft: '5px', padding: '0', fontSize: '18px' }}
                            onClick={() => handleEditRouteSet(row.original)}
                        >
                            <i className='RS_EDIT' />
                        </IconButton>
                        <IconButton
                            title={t('RoutePlans.RouteSet.Actions.Delete')}
                            color='primary'
                            className={'icon_button'}
                            style={{ color: '#16a8a6', marginLeft: '5px', padding: '0', fontSize: '18px' }}
                            onClick={() => handleDeleteRouteSet(row.original)}
                        >
                            <i className='RS_DELETE' />
                        </IconButton>
                    </div>
                );
            },
        },
    ];

    React.useEffect(() => {
        getRouteSets(workspace.value, props.routePlanId, siteMode, project).then(
            (res) => {
                if (res && res.data) {
                    res.data.forEach((d) => {
                        d.PostEndTime = getPreOrPostTripDuration(d.PostEndTime);
                        d.PreStartTime = getPreOrPostTripDuration(d.PreStartTime);
                    });
                    setData(res.data);
                    setRefreshRouteSet(false);
                }
            },
            (err) => {
                setData([]);
                setRefreshRouteSet(false);
            },
        );
    }, [props.routePlanId, refreshRouteSet]);

    const handleNewRouteSet = () => {
        const record = data.find((d) => d.Route === defaultName);
        if (record) {
            setMode('New');
            let r = { ...record };
            r.Route = '';
            handleEditRouteSetDialog(r);
        }
    };

    const handleCopyRouteSet = (data) => {
        setMode('Copy');
        setEditingName(data.Route);
        let r = { ...data };
        r.Route = '';
        handleEditRouteSetDialog(r);
    };

    const handleEditRouteSet = (data) => {
        setMode('Edit');
        setEditingName(data.Route);
        handleEditRouteSetDialog(data);
    };

    const handleEditRouteSetDialog = async (data) => {
        setTab(0);

        let facilityResponse = await getFacilities(workspace.value, siteMode, project);
        const facilities = [{ value: -2, label: 'Closest' }].concat(
            facilityResponse.data.map((facility) => ({ value: facility.Id, label: facility.Name })),
        );
        const startFacilities = [{ value: -2, label: 'Closest' }].concat(
            facilityResponse.data.filter((f) => f.StartEnd === true).map((facility) => ({ value: facility.Id, label: facility.Name })),
        );
        const intermediateFacilities = [{ value: -2, label: 'Closest' }].concat(
            facilityResponse.data.filter((f) => f.Intermediate === true).map((facility) => ({ value: facility.Id, label: facility.Name })),
        );

        setFacilityOptions(startFacilities.sort((a, b) => a.label.localeCompare(b.label)));
        setIntermediateFacilityTripOptions(intermediateFacilities.sort((a, b) => a.label.localeCompare(b.label)));

        let driverResponse = await getDrivers(workspace.value);
        setDriverOptions(
            driverResponse.data.map((driver) => ({ value: driver.Id, label: driver.DriverName })).sort((a, b) => a.label.localeCompare(b.label)),
        );

        let truckResponse = await getTrucks(workspace.value);
        setTruckOptions(
            truckResponse.data.map((truck) => ({ value: truck.Id, label: truck.TruckName })).sort((a, b) => a.label.localeCompare(b.label)),
        );

        setTarget(data);

        setEditRouteSetDialog(true);

        let newTrips = [...data.TripInfoList.sort((a, b) => a.TripNum - b.TripNum)];
        newTrips.forEach((forTrip, i, forTrips) => (forTrips[i].Label = facilities.find((f) => f.value === forTrip.FacilityId)?.label));
        let newIntermediateFacility = newTrips.shift();
        let newLastTripFacility = newTrips.pop();

        // RO-2411
        // if new trip is an empty array, there will need to be default values
        // closest (-2) will be the default value
        const defaultBaseDataForFacility = {
            FacilityId: -2,
            // Id: <Id> not present
            Label: 'Closest',
            RouteSetId: data.Id,
        };

        // if there is now label found, this means this is not found on the variable called facilities
        // this variable is res data from '/workspaces/<wp>/facilities' and { value: -2, label: 'Closest' }
        //newIntermediateFacility && newLastTripFacility
        if (!newIntermediateFacility || !newIntermediateFacility.Label) newIntermediateFacility = { ...defaultBaseDataForFacility, TripNum: 0 };
        if (!newLastTripFacility || !newLastTripFacility.Label) newLastTripFacility = { ...defaultBaseDataForFacility, TripNum: 99 };

        setIntermediateFacility(newIntermediateFacility);
        setLastTripFacility(newLastTripFacility);

        setTrips(newTrips);

        setBreaks(data.BreakInfoList.sort((a, b) => a.Earliest.localeCompare(b.Earliest)));
    };

    const handleEditClose = async (option) => {
        if (option === 'SAVE') {
            let t = { ...target };
            t.BreakInfoList = breaks;
            t.TripInfoList = [intermediateFacility, ...trips, lastTripFacility];

            if (mode === 'New' || mode === 'Copy') {
                try {
                    await postRouteSet(workspace.value, t);

                    let logData = {
                        WorkspaceId: workspace.value,
                        ActionId: 1411,
                        Mode: siteMode,
                        Success: true,
                        Metadata: t.Route,
                    };
                    updateUserActivity(logData);

                    const response = await getRouteSets(workspace.value, props.routePlanId, siteMode, project);

                    response.data.map((d) => {
                        d.PostEndTime = getPreOrPostTripDuration(d.PostEndTime);
                        d.PreStartTime = getPreOrPostTripDuration(d.PreStartTime);
                    });

                    setData(response.data);
                    props.refreshRoutePlan();

                    setEditRouteSetDialog(false);
                    setTarget({});
                    setDriverOptions({});
                    setTruckOptions({});
                    setFacilityOptions({});
                    setIntermediateFacilityTripOptions({});
                    setIntermediateFacility({});
                    setLastTripFacility({});
                    setTrips([]);
                    setBreaks([]);
                } catch (e) {
                    let logData = {
                        WorkspaceId: workspace.value,
                        ActionId: 1411,
                        Mode: siteMode,
                        Success: false,
                        Metadata: t.Route,
                    };
                    updateUserActivity(logData);

                    const message = e.response.data.Message;
                    const colonIndex = message.indexOf(':', message.indexOf(':') + 1);
                    props.handleDialogOpen(colonIndex === -1 ? message : message.substr(0, colonIndex));
                }
            } else if (mode === 'Edit') {
                try {
                    await putRouteSet(workspace.value, t);

                    let logData = {
                        WorkspaceId: workspace.value,
                        ActionId: 1412,
                        Mode: siteMode,
                        Success: true,
                        Metadata: t.Route,
                    };
                    updateUserActivity(logData);

                    const response = await getRouteSets(workspace.value, props.routePlanId, siteMode, project);

                    response.data.map((d) => {
                        d.PostEndTime = getPreOrPostTripDuration(d.PostEndTime);
                        d.PreStartTime = getPreOrPostTripDuration(d.PreStartTime);
                    });

                    setData(response.data);
                    props.refreshRoutePlan();

                    setEditRouteSetDialog(false);
                    setTarget({});
                    setDriverOptions({});
                    setTruckOptions({});
                    setFacilityOptions({});
                    setIntermediateFacilityTripOptions({});
                    setIntermediateFacility({});
                    setLastTripFacility({});
                    setTrips([]);
                    setBreaks([]);
                } catch (e) {
                    let logData = {
                        WorkspaceId: workspace.value,
                        ActionId: 1412,
                        Mode: siteMode,
                        Success: false,
                        Metadata: t.Route,
                    };
                    updateUserActivity(logData);

                    const message = e.response.data.Message;
                    const colonIndex = message.indexOf(':', message.indexOf(':') + 1);
                    props.handleDialogOpen(colonIndex === -1 ? message : message.substr(0, colonIndex));
                }
            }
        } else if (option === 'CANCEL') {
            setEditRouteSetDialog(false);
            setTarget(target);
        }
    };

    const handleDeleteRouteSet = (data) => {
        setMode('Delete');
        setTarget(data);
        setDeleteRouteSetDialog(true);
    };

    const handleDeleteClose = async (option) => {
        if (option === 'DELETE') {
            try {
                await deleteRouteSet(workspace.value, target);

                let logData = {
                    WorkspaceId: workspace.value,
                    ActionId: 1413,
                    Mode: siteMode,
                    Success: true,
                    Metadata: target.Route,
                };
                updateUserActivity(logData);

                const response = await getRouteSets(workspace.value, props.routePlanId, siteMode, project);

                response.data.map((d) => {
                    d.PostEndTime = getPreOrPostTripDuration(d.PostEndTime);
                    d.PreStartTime = getPreOrPostTripDuration(d.PreStartTime);
                });

                setData(response.data);
                props.refreshRoutePlan();
            } catch (e) {
                let logData = {
                    WorkspaceId: workspace.value,
                    ActionId: 1413,
                    Mode: siteMode,
                    Success: false,
                    Metadata: target.Route,
                };
                updateUserActivity(logData);

                const message = e.response.data.Message;
                const colonIndex = message.indexOf(':', message.indexOf(':') + 1);
                props.handleDialogOpen(colonIndex === -1 ? message : message.substr(0, colonIndex));
            }
        }
        setDeleteRouteSetDialog(false);
        setTarget({});
    };

    const updateRoutePlan = (e, value, label) => {
        let t = { ...target };
        t[value] = e.value;
        t[label] = e.label;
        setTarget(t);
    };

    const updateServeTimeMult = (e) => {
        let t = { ...target };
        t['ServeTimeMult'] = e.value;
        setTarget(t);
    };

    const updateIntermediateFacilitySelect = (e) => {
        let f = { ...intermediateFacility };
        f['FacilityId'] = e.value;
        f['Label'] = e.label;
        setIntermediateFacility(f);
    };

    const updateIntermediateTripSelect = (e, tr) => {
        let newTrips = [...trips];
        let index = newTrips.findIndex((x) => x.Id == tr.Id);
        tr['FacilityId'] = e.value;
        tr['Label'] = e.label;
        newTrips[index] = tr;
        setTrips(newTrips);
    };

    const updateLastTripFacilitySelect = (e) => {
        let f = { ...lastTripFacility };
        f['FacilityId'] = e.value;
        f['Label'] = e.label;
        setLastTripFacility(f);
    };

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    const addTrip = (e) => {
        let t = { ...target };
        let newTrips = [...trips];
        const ids = Array.from(Array(99).keys());
        let availableNumber = 0;

        for (let i = 1; i < ids.length; i++) {
            if (newTrips.filter((n) => n.TripNum == ids[i]).length === 0) {
                availableNumber = i;
                break;
            }
        }
        newTrips.push({
            Id: 0 - trips.length,
            RouteSetId: t.Id,
            FacilityId: intermediateFacility.FacilityId,
            TripNum: availableNumber,
            Label: intermediateFacility.Label,
        });
        setTrips(newTrips.sort((a, b) => a.TripNum - b.TripNum));
    };

    const updateTrip = (e, tr) => {
        if (e.target.value && e.target.value < 1) {
            return;
        } else if (e.target.value) {
            let oldTrips = [...trips];
            let duplicate = oldTrips.filter((o) => o.TripNum == e.target.value);
            if (duplicate.length === 0) {
                let targetTrip = trips.find((x) => x === tr);
                oldTrips.map((t) => {
                    if (t.Id === targetTrip.Id) {
                        t.TripNum = e.target.value;
                    }
                });
                setTrips(oldTrips);
            }
        } else {
            let sameTrips = [...trips];
            setTrips(sameTrips);
        }
    };

    const deleteTrip = (tr) => {
        let newTrips = trips.filter((x) => x != tr);
        setTrips(newTrips);
    };

    const sortTrip = (e) => {
        let newTrips = [...trips];
        setTrips(newTrips.sort((a, b) => a.TripNum - b.TripNum));
    };

    const addBreak = (e) => {
        let t = { ...target };
        let newBreaks = [...breaks, { Id: 0 - breaks.length, RouteSetId: t.Id, Duration: '0.25', Earliest: '00:00', Latest: '00:00' }];
        setBreaks(newBreaks);
    };

    const updateBreakEarliest = (e, br) => {
        let newBreaks = [...breaks];
        let index = newBreaks.findIndex((x) => x.Id === br.Id);
        if (e) {
            br['Earliest'] = e.value;
        } else {
            br['Earliest'] = '';
        }
        newBreaks[index] = br;
        setBreaks(newBreaks.sort((a, b) => a.Earliest.localeCompare(b.Earliest)));
    };

    const updateBreakLatest = (e, br) => {
        let newBreaks = [...breaks];
        let index = newBreaks.findIndex((x) => x.Id === br.Id);
        if (e) {
            br['Latest'] = e.value;
        } else {
            br['Latest'] = '';
        }
        newBreaks[index] = br;
        setBreaks(newBreaks);
    };

    const updateBreakDuration = (e, br) => {
        let newBreaks = [...breaks];
        let index = newBreaks.findIndex((x) => x.Id === br.Id);
        br['Duration'] = e.value;
        newBreaks[index] = br;
        setBreaks(newBreaks);
    };

    const deleteBreak = (br) => {
        let newBreaks = breaks.filter((x) => x != br);
        setBreaks(newBreaks);
    };

    const validForm = () => {
        if (!target.Route) {
            return false;
        }
        if (mode === 'New') {
            let duplicate = data.filter((d) => d.Route.toLowerCase() === target.Route.toLowerCase());
            if (duplicate.length > 0) {
                return false;
            }
        } else if (mode === 'Copy' || mode === 'Edit') {
            let duplicate = data.filter(
                (d) => d.Route.toLowerCase() === target.Route.toLowerCase() && target.Route.toLowerCase() !== editingName.toLowerCase(),
            );
            if (duplicate.length > 0) {
                return false;
            }
        }
        return true;
    };

    const validateRouteName = (e) => {
        let t = { ...target };
        t[e.target.name] = e.target.value;
        setTarget(t);
        const isValid = /^([A-Za-z0-9_]+)$/.test(e.target.value);
        if (e.target.value === '' || isValid) {
            setRouteNameError(false);
            setErrorMsg('');
        } else {
            setRouteNameError(true);
            setErrorMsg('Route name can only consist of letter, number and underscore.');
        }
    };

    const updateOptimizeRouteNumber = (e) => {
        props.updateRouteNumber(props.routePlanId, e.target.value);
    };

    const saveOptimizeRouteNumber = () => {
        props.saveRouteNumber(props.routePlanId);
    };

    const handleCreateServeTimeMult = (input) => {
        if (!input || isNaN(input) || !Number.isInteger(Number(input))) {
            return;
        }
        if (parseInt(input) > 0 && parseInt(input) < 901) {
            const newItem = {
                label: input + '%',
                value: (input / 100).toFixed(2),
            };
            let options = [...serveTimeOptions];
            console.log(options);
            if (options.filter((l) => l.value == input / 100).length === 0) {
                options.push(newItem);
            }
            options.sort((a, b) => a.label.localeCompare(b.label, 'en', { numeric: true }));
            setServeTimeOptions(options);
            updateServeTimeMult({ value: (input / 100).toFixed(2) });
        } else {
            return;
        }
    };

    return (
        <Grid
            item
            xs={12}
        >
            <div style={{ maxWidth: '100%' }}>
                <div style={{ height: '70px', position: 'relative' }}>
                    <p style={{ fontSize: '18px', margin: '5px 10px' }}>{title}</p>
                    <AddNewRouteButton handleNewRouteSet={handleNewRouteSet} />
                    <RouteMethodAndZones
                        d={d}
                        methodOptions={methodOptions}
                        updateOptimizeRouteNumber={updateOptimizeRouteNumber}
                        saveOptimizeRouteNumber={saveOptimizeRouteNumber}
                        GenerateMethod={props.GenerateMethod}
                        enableNeighborhood={props.enableNeighborhood}
                        routePlanId={props.routePlanId}
                        updateRouteOptimizer={props.updateRouteOptimizer}
                        updateNeighborhoodCluster={props.updateNeighborhoodCluster}
                        NumRoutes={props.NumRoutes}
                    />
                </div>
                <MaterialReactTable
                    columns={columns}
                    data={data}
                    enableTopToolbar={false}
                    enableStickyHeader={true}
                    enableColumnFilters={false}
                    enablePagination={false}
                    enableBottomToolbar={false}
                    enableColumnActions={false}
                    enableFullScreenToggle={false}
                    enableDensityToggle={false}
                    enableHiding={false}
                    initialState={{
                        density: 'compact',
                        showGlobalFilter: false,
                        sorting: [{ id: 'Route', desc: false }],
                    }}
                    muiTableContainerProps={{
                        sx: { maxHeight: '370px' },
                    }}
                />
            </div>
            {editRouteSetDialog && (
                <EditRouteSetDialogue
                    siteMode={siteMode}
                    editRouteSetDialog={editRouteSetDialog}
                    mode={mode}
                    title={'Route'}
                    tab={tab}
                    handleTabChange={handleTabChange}
                    routeNameError={routeNameError}
                    errorMsg={errorMsg}
                    target={target}
                    validateRouteName={validateRouteName}
                    defaultName={defaultName}
                    updateRoutePlan={updateRoutePlan}
                    driverOptions={driverOptions}
                    truckOptions={truckOptions}
                    facilityOptions={facilityOptions}
                    updateServeTimeMult={updateServeTimeMult}
                    serveTimeOptions={serveTimeOptions}
                    handleCreateServeTimeMult={handleCreateServeTimeMult}
                    intermediateFacility={intermediateFacility}
                    updateIntermediateTripSelect={updateIntermediateTripSelect}
                    intermediateFacilityTripOptions={intermediateFacilityTripOptions}
                    updateIntermediateFacilitySelect={updateIntermediateFacilitySelect}
                    lastTripFacility={lastTripFacility}
                    updateLastTripFacilitySelect={updateLastTripFacilitySelect}
                    trips={trips}
                    breaks={breaks}
                    updateBreakEarliest={updateBreakEarliest}
                    updateBreakLatest={updateBreakLatest}
                    handleEditClose={handleEditClose}
                    editingName={editingName}
                    addTrip={addTrip}
                    deleteBreak={deleteBreak}
                    deleteTrip={deleteTrip}
                    addBreak={addBreak}
                    updateTrip={updateTrip}
                    sortTrip={sortTrip}
                    updateBreakDuration={updateBreakDuration}
                    validForm={validForm}
                />
            )}
            {deleteRouteSetDialog && (
                <DeleteDialogue
                    deleteDriverDialog={deleteRouteSetDialog}
                    mode={mode}
                    title={'RouteSet'}
                    target={target}
                    handleDeleteClose={handleDeleteClose}
                />
            )}
        </Grid>
    );
};

export default RouteSetTable;
