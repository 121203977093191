import React from 'react';
import { RODialog } from '../../../common';
import Grid from '@mui/material/Grid';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DxButton from 'devextreme-react/button';
import { RODialogLabel } from '../../../common';
import Popover from 'devextreme-react/popover';
import SelectBox from 'devextreme-react/select-box';
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import { Validator, RequiredRule, CompareRule, EmailRule, PatternRule, StringLengthRule } from 'devextreme-react/validator';
import ValidationSummary from 'devextreme-react/validation-summary';
import { useTranslation } from 'react-i18next';

export const AddEditUsersDialog = (props) => {
    const {
        showEditUserDialog,
        updateUserForm,
        userTarget,
        passwordMode,
        setPasswordMode,
        handleEditUserClose,
        editUserPasswordVisible,
        setEditUserPasswordVisible,
        userPopoverVisible,
        setUserPopoverVisible,
        confirmPasswordMode,
        setConfirmPasswordMode,
        userMode,
        assignableRoles,
        assignableGroups,
    } = props;
    const { t } = useTranslation();
    return (
        <RODialog
            maxWidth='xs'
            fullWidth={true}
            open={showEditUserDialog}
            dialogTitle={`${userMode === 'New' ? t('AccountManagement.Users.Actions.AddUser') : t('AccountManagement.Users.Actions.EditUser')}`}
            dialogContent={
                <DialogContent>
                    <Grid
                        container
                        spacing={'10px'}
                        style={{ paddingTop: '10px' }}
                    >
                        <Grid
                            item
                            xs={4}
                        >
                            <RODialogLabel>{t('AccountManagement.Users.Form.UserName')} *</RODialogLabel>
                        </Grid>
                        <Grid
                            item
                            xs={8}
                        >
                            <TextBox
                                name='UserName'
                                value={userTarget.UserName}
                                inputAttr={{ autocomplete: 'new-username' }}
                                onValueChanged={(e) => {
                                    updateUserForm('UserName', e.value);
                                }}
                            >
                                <Validator>
                                    <RequiredRule message={t('AccountManagement.Users.Messages.Message1')} />
                                </Validator>
                            </TextBox>
                        </Grid>
                        <Grid
                            item
                            xs={4}
                        >
                            <RODialogLabel>{t('AccountManagement.Users.Form.PreferredUserName')}</RODialogLabel>
                        </Grid>
                        <Grid
                            item
                            xs={8}
                        >
                            <TextBox
                                name='PreferredUsername'
                                value={userTarget.PreferredUsername}
                                onValueChanged={(e) => {
                                    updateUserForm('PreferredUsername', e.value);
                                }}
                            ></TextBox>
                        </Grid>
                        {editUserPasswordVisible && (
                            <Grid
                                item
                                xs={5}
                            >
                                <RODialogLabel>{t('AccountManagement.Users.Form.Password')}</RODialogLabel>
                                <TextBox
                                    id='Password'
                                    name='Password'
                                    mode={passwordMode}
                                    inputAttr={{ autocomplete: 'new-password' }}
                                    onValueChanged={(e) => {
                                        updateUserForm('Password', e.value);
                                    }}
                                >
                                    <TextBoxButton
                                        name='password'
                                        location='after'
                                        options={{
                                            icon: passwordMode === 'password' ? 'equal' : 'notequal',
                                            hint:
                                                passwordMode === 'password'
                                                    ? t('AccountManagement.Users.Form.ShowPassword')
                                                    : t('AccountManagement.Users.Form.HidePassword'),
                                            type: 'normal',
                                            onClick: () => {
                                                if (passwordMode === 'text') setPasswordMode('password');
                                                else setPasswordMode('text');
                                            },
                                        }}
                                    ></TextBoxButton>
                                    <Validator>
                                        <RequiredRule message={t('AccountManagement.Users.Messages.Message2')} />
                                        <PatternRule
                                            message={t('AccountManagement.Users.Messages.Message3')}
                                            pattern={/[A-Z]/}
                                        />
                                        <PatternRule
                                            message={t('AccountManagement.Users.Messages.Message4')}
                                            pattern={/[a-z]/}
                                        />
                                        <PatternRule
                                            message={t('AccountManagement.Users.Messages.Message5')}
                                            pattern={/\d/}
                                        />
                                        <PatternRule
                                            message={t('AccountManagement.Users.Messages.Message6')}
                                            pattern={/[$-/:-?{-~!"^_`@#\[\]\\]/}
                                        />
                                        <StringLengthRule
                                            message={t('AccountManagement.Users.Messages.Message7')}
                                            min={8}
                                            max={16}
                                        />
                                    </Validator>
                                </TextBox>
                            </Grid>
                        )}
                        {editUserPasswordVisible && (
                            <Grid
                                item
                                xs={1}
                            >
                                <RODialogLabel>&nbsp;</RODialogLabel>
                                <Popover
                                    target='#PasswordHint'
                                    position='top'
                                    width={250}
                                    visible={userPopoverVisible}
                                >
                                    <ul style={{ listStyle: 'none' }}>
                                        <li>
                                            <font style={{ color: userTarget.Password && userTarget.Password.match(/[A-Z]/) ? 'green' : 'red' }}>
                                                {t('AccountManagement.Users.Labels.AtLeast')}{' '}
                                                <strong>{t('AccountManagement.Users.Messages.Message13')}</strong>
                                            </font>
                                        </li>
                                        <li>
                                            <font style={{ color: userTarget.Password && userTarget.Password.match(/[a-z]/) ? 'green' : 'red' }}>
                                                {t('AccountManagement.Users.Labels.AtLeast')}{' '}
                                                <strong>{t('AccountManagement.Users.Messages.Message14')}</strong>
                                            </font>
                                        </li>
                                        <li>
                                            <font style={{ color: userTarget.Password && userTarget.Password.match(/\d/) ? 'green' : 'red' }}>
                                                {t('AccountManagement.Users.Labels.AtLeast')}
                                                <strong>{t('AccountManagement.Users.Messages.Message15')}</strong>
                                            </font>
                                        </li>
                                        <li>
                                            <font
                                                style={{
                                                    color:
                                                        userTarget.Password && userTarget.Password.match(/[$-/:-?{-~!"^_`@#\[\]\\]/)
                                                            ? 'green'
                                                            : 'red',
                                                }}
                                            >
                                                {t('AccountManagement.Users.Labels.AtLeast')}{' '}
                                                <strong>{t('AccountManagement.Users.Messages.Message16')}</strong>
                                            </font>
                                        </li>
                                        <li>
                                            <font
                                                style={{
                                                    color:
                                                        userTarget.Password && userTarget.Password.length > 7 && userTarget.Password.length < 17
                                                            ? 'green'
                                                            : 'red',
                                                }}
                                            >
                                                {t('AccountManagement.Users.Labels.Between')} <strong> 8 - 16 </strong>{' '}
                                                {t('AccountManagement.Users.Labels.Characters')}
                                            </font>
                                        </li>
                                    </ul>
                                </Popover>{' '}
                                <a
                                    id='PasswordHint'
                                    onMouseEnter={() => {
                                        setUserPopoverVisible(true);
                                    }}
                                    onMouseLeave={() => {
                                        setUserPopoverVisible(false);
                                    }}
                                >
                                    <DxButton
                                        icon='info'
                                        type={
                                            userTarget.Password &&
                                            userTarget.Password.match(/[A-Z]/) &&
                                            userTarget.Password.match(/[a-z]/) &&
                                            userTarget.Password.match(/\d/) &&
                                            userTarget.Password.match(/[$-/:-?{-~!"^_`@#\[\]\\]/) &&
                                            userTarget.Password.length > 7 &&
                                            userTarget.Password.length < 17
                                                ? 'success'
                                                : 'danger'
                                        }
                                    ></DxButton>
                                </a>
                            </Grid>
                        )}
                        {editUserPasswordVisible && (
                            <Grid
                                item
                                xs={6}
                            >
                                <RODialogLabel>{t('AccountManagement.Users.Form.ConfirmPassword')}</RODialogLabel>
                                <TextBox
                                    name='ConfirmPassword'
                                    mode={confirmPasswordMode}
                                    inputAttr={{ autocomplete: 'new-password' }}
                                    onValueChanged={(e) => {
                                        updateUserForm('ConfirmPassword', e.value);
                                    }}
                                >
                                    <TextBoxButton
                                        name='password'
                                        location='after'
                                        options={{
                                            icon: confirmPasswordMode === 'password' ? 'equal' : 'notequal',
                                            hint:
                                                confirmPasswordMode === 'password'
                                                    ? t('AccountManagement.Users.Form.ShowPassword')
                                                    : t('AccountManagement.Users.Form.HidePassword'),
                                            type: 'normal',
                                            onClick: () => {
                                                if (confirmPasswordMode === 'text') setConfirmPasswordMode('password');
                                                else setConfirmPasswordMode('text');
                                            },
                                        }}
                                    ></TextBoxButton>
                                    <Validator>
                                        <RequiredRule message={t('AccountManagement.Users.Messages.Message8')} />
                                        <CompareRule
                                            message={t('AccountManagement.Users.Messages.Message9')}
                                            comparisonTarget={() => {
                                                return userTarget.Password;
                                            }}
                                        />
                                    </Validator>
                                </TextBox>
                            </Grid>
                        )}
                        <Grid
                            item
                            xs={3}
                        >
                            <RODialogLabel>{t('AccountManagement.Users.Form.Email')} *</RODialogLabel>
                        </Grid>
                        <Grid
                            item
                            xs={9}
                        >
                            <TextBox
                                name='Email'
                                value={userTarget.Email}
                                inputAttr={{ autocomplete: 'new-email' }}
                                onValueChanged={(e) => {
                                    updateUserForm('Email', e.value);
                                }}
                            >
                                <Validator>
                                    <RequiredRule message={t('AccountManagement.Users.Messages.Message10')} />
                                    <EmailRule message={t('AccountManagement.Users.Messages.Message11')} />
                                </Validator>
                            </TextBox>
                        </Grid>
                        <Grid
                            item
                            xs={6}
                        >
                            <RODialogLabel>{t('AccountManagement.Users.Form.FirstName')}</RODialogLabel>
                            <TextBox
                                name='FirstName'
                                value={userTarget.FirstName}
                                inputAttr={{ autocomplete: 'new-firstname' }}
                                onValueChanged={(e) => {
                                    updateUserForm('FirstName', e.value);
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={6}
                        >
                            <RODialogLabel>{t('AccountManagement.Users.Form.LastName')}</RODialogLabel>
                            <TextBox
                                name='LastName'
                                value={userTarget.LastName}
                                inputAttr={{ autocomplete: 'new-lastname' }}
                                onValueChanged={(e) => {
                                    updateUserForm('LastName', e.value);
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={3}
                        >
                            <RODialogLabel>{t('AccountManagement.Users.Form.Role')}</RODialogLabel>
                        </Grid>
                        <Grid
                            item
                            xs={9}
                        >
                            <SelectBox
                                dataSource={assignableRoles}
                                value={userTarget.RoleId}
                                displayExpr='text'
                                valueExpr='id'
                                onValueChanged={(e) => {
                                    updateUserForm('RoleId', e.value);
                                }}
                            >
                                <Validator>
                                    <RequiredRule message={t('AccountManagement.Users.Messages.Message12')} />
                                </Validator>
                            </SelectBox>
                        </Grid>
                        <Grid
                            item
                            xs={3}
                        >
                            <RODialogLabel>{t('AccountManagement.Users.Form.Group')}</RODialogLabel>
                        </Grid>
                        <Grid
                            item
                            xs={9}
                        >
                            <SelectBox
                                dataSource={assignableGroups}
                                value={userTarget.GroupId}
                                displayExpr='text'
                                valueExpr='id'
                                onValueChanged={(e) => {
                                    updateUserForm('GroupId', e.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                    <ValidationSummary id='summary'></ValidationSummary>
                </DialogContent>
            }
            dialogActions={
                <DialogActions style={{ justifyContent: 'right' }}>
                    {userMode === 'Edit' && (
                        <div style={{ justifyContent: 'left' }}>
                            <DxButton
                                width={200}
                                text={
                                    editUserPasswordVisible
                                        ? t('AccountManagement.Users.Form.CancelChangePassword')
                                        : t('AccountManagement.Users.Form.ChangePassword')
                                }
                                type='normal'
                                stylingMode='text'
                                onClick={() => {
                                    setEditUserPasswordVisible(!editUserPasswordVisible);
                                }}
                            />
                        </div>
                    )}
                    <DxButton
                        id='button'
                        text={t('Common.Save')}
                        type='default'
                        useSubmitBehavior={true}
                        onClick={() => {
                            handleEditUserClose('SAVE');
                        }}
                    />
                    <DxButton
                        id='button'
                        text={t('Common.Cancel')}
                        type='normal'
                        useSubmitBehavior={false}
                        onClick={() => {
                            handleEditUserClose('CANCEL');
                        }}
                    />
                </DialogActions>
            }
        />
    );
};
