import React from 'react';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ReactSelect from 'react-select';
import MaterialReactTable from 'material-react-table';
import CloseIcon from '@mui/icons-material/Close';
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { MRT_Localization_ES } from 'material-react-table/locales/es';

export const EnterPlannerDialog = (props) => {
    const {
        openEnterPlannerDialog,
        handleEnterPlannerDialogClose,
        setRefreshProject,
        projectServiceDay,
        openPlannerMode,
        setOpenPlannerMode,
        routingProjectRouteList,
        projectName,
        handleChangeProjectName,
        projectRouteSelection,
        setProjectRouteSelection,
        routingProjects,
        projectSelection,
        setProjectSelection,
        handleProjectServiceDayChanged,
        shareProject,
        handleChangeShareProject,
    } = props;

    const { t } = useTranslation();

    const locale = localStorage.getItem('i18nextLng') || null;

    let localization = '';
    if (locale === 'es') {
        localization = MRT_Localization_ES;
    } else {
        localization = MRT_Localization_EN;
    }

    React.useEffect(() => {
        if (openEnterPlannerDialog) {
            setRefreshProject(true);
        }
    }, [openEnterPlannerDialog]);

    const defaultServiceDayList = [
        { value: 0, label: t('Global.DayOfWeek.AllDays'), display: true },
        { value: 1, label: t('Global.DayOfWeek.Monday'), display: true },
        { value: 2, label: t('Global.DayOfWeek.Tuesday'), display: true },
        { value: 3, label: t('Global.DayOfWeek.Wednesday'), display: true },
        { value: 4, label: t('Global.DayOfWeek.Thursday'), display: true },
        { value: 5, label: t('Global.DayOfWeek.Friday'), display: true },
        { value: 6, label: t('Global.DayOfWeek.Saturday'), display: true },
        { value: 7, label: t('Global.DayOfWeek.Sunday'), display: true },
    ];

    return (
        <Dialog
            open={openEnterPlannerDialog}
            maxWidth={'md'}
            id={'EnterPlannerDialog'}
        >
            <DialogTitle sx={{ m: 0, p: 2 }}>
                {openPlannerMode === 'OPEN' ? t('Global.PlannerDialog.OpenProject') : t('Global.PlannerDialog.CreateProject')}
                <IconButton
                    id={'closeButton'}
                    aria-label='close'
                    onClick={() => handleEnterPlannerDialogClose('CANCEL')}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id='map-alert-description'>{t('Global.Messages.PlannerDialogWarning')}</DialogContentText>
                <br />
                <Grid container>
                    <Grid
                        item
                        xs={openPlannerMode !== null ? 4 : 12}
                    >
                        <Stack
                            justifyContent='center'
                            alignItems='center'
                            spacing={2}
                        >
                            <Button
                                variant='outlined'
                                onClick={() => setOpenPlannerMode('CREATE')}
                                color='success'
                                sx={{ width: '175px' }}
                            >
                                {t('Global.PlannerDialog.CreateProject')}
                            </Button>

                            <Button
                                variant='outlined'
                                onClick={() => setOpenPlannerMode('OPEN')}
                                sx={{ width: '175px' }}
                            >
                                {t('Global.PlannerDialog.OpenProject')}
                            </Button>
                        </Stack>
                    </Grid>
                    {openPlannerMode === 'CREATE' && (
                        <Grid
                            item
                            xs={8}
                            sx={{ borderLeft: '1px solid #ccc' }}
                        >
                            <FormControlLabel
                                control={
                                    <TextField
                                        variant='outlined'
                                        value={projectName}
                                        onChange={handleChangeProjectName}
                                        sx={{ height: '30px', marginLeft: '10px' }}
                                    />
                                }
                                sx={{ height: '30px' }}
                                label={<b>{t('Global.PlannerDialog.ProjectName')}: </b>}
                                labelPlacement='start'
                                id={'projectNameInput'}
                            />

                            <FormControl
                                style={{ margin: '10px 0' }}
                                variant='standard'
                            >
                                <FormGroup row>
                                    <FormControlLabel
                                        value='start'
                                        control={
                                            <div style={{ width: '223px', marginLeft: '18px' }}>
                                                <ReactSelect
                                                    className='basic-single'
                                                    classNamePrefix='select'
                                                    isClearable={false}
                                                    isSearchable={true}
                                                    name='Project_Serviceday_Select'
                                                    id='Project_Serviceday_Select'
                                                    value={
                                                        projectServiceDay
                                                            ? defaultServiceDayList.find((s) => s.value == projectServiceDay.value)
                                                            : null
                                                    }
                                                    onChange={handleProjectServiceDayChanged}
                                                    options={defaultServiceDayList}
                                                    menuPortalTarget={document.body}
                                                    styles={{
                                                        option: (provided, state) => ({
                                                            ...provided,
                                                            display: state.data.display ? '' : 'none',
                                                            padding: 5,
                                                        }),
                                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                    }}
                                                />
                                            </div>
                                        }
                                        label={<b>{t('Global.PlannerDialog.ServiceDay')}:&nbsp;</b>}
                                        labelPlacement='start'
                                    />
                                </FormGroup>
                            </FormControl>

                            <div
                                id='ProjectSelectionTable'
                                style={{ maxWidth: '100%', padding: '5px', height: '280px' }}
                            >
                                <MaterialReactTable
                                    columns={[{ header: t('Global.PlannerDialog.Routes'), accessorKey: 'Id' }]}
                                    data={routingProjectRouteList}
                                    enableTopToolbar={false}
                                    enableStickyHeader={true}
                                    enableColumnFilters={false}
                                    enablePagination={false}
                                    enableBottomToolbar={false}
                                    enableColumnActions={false}
                                    enableFullScreenToggle={false}
                                    enableDensityToggle={false}
                                    enableHiding={false}
                                    enableRowSelection={true}
                                    selectAllMode={'all'}
                                    getRowId={(originalRow) => originalRow.Id}
                                    onRowSelectionChange={setProjectRouteSelection}
                                    initialState={{
                                        rowSelection: {},
                                        density: 'compact',
                                        showGlobalFilter: false,
                                        sorting: [{ id: 'Id', desc: false }],
                                    }}
                                    state={{
                                        rowSelection: projectRouteSelection,
                                    }}
                                    muiTableContainerProps={{
                                        sx: { maxHeight: '260px' },
                                    }}
                                    muiSelectAllCheckboxProps={{
                                        color: 'default',
                                        title: '',
                                    }}
                                    muiSelectCheckboxProps={{
                                        color: 'default',
                                        title: '',
                                    }}
                                    localization={localization}
                                />
                            </div>

                            <FormControlLabel
                                label={<span style={{ marginLeft: '10px' }}>{t('Global.PlannerDialog.ShareProject')}</span>}
                                style={{ fontSize: 18, marginLeft: '2px', marginRight: '10px' }}
                                control={
                                    <Checkbox
                                        name='anchorWithServiceDay'
                                        checked={shareProject}
                                        onChange={(e) => handleChangeShareProject(e)}
                                    />
                                }
                            />
                        </Grid>
                    )}
                    {openPlannerMode === 'OPEN' && (
                        <Grid
                            item
                            xs={8}
                            sx={{ borderLeft: '1px solid #ccc' }}
                        >
                            <div
                                id='ProjectSelectionTable'
                                style={{ maxWidth: '100%', padding: '5px', height: '280px' }}
                            >
                                <MaterialReactTable
                                    columns={[
                                        { header: t('Global.PlannerDialog.ProjectName'), accessorKey: 'SandboxName' },
                                        { header: t('Global.PlannerDialog.DateModified'), accessorKey: 'DateModified' },
                                    ]}
                                    data={routingProjects}
                                    enableTopToolbar={false}
                                    enableStickyHeader={true}
                                    enableColumnFilters={false}
                                    enablePagination={false}
                                    enableBottomToolbar={false}
                                    enableColumnActions={false}
                                    enableFullScreenToggle={false}
                                    enableDensityToggle={false}
                                    enableHiding={false}
                                    enableRowSelection={true}
                                    enableMultiRowSelection={false}
                                    enableSelectAll={false}
                                    getRowId={(originalRow) => originalRow.ID}
                                    onRowSelectionChange={setProjectSelection}
                                    initialState={{
                                        rowSelection: {},
                                        density: 'compact',
                                        showGlobalFilter: false,
                                        sorting: [{ id: 'SandboxName', desc: false }],
                                    }}
                                    state={{
                                        rowSelection: projectSelection,
                                    }}
                                    muiTableContainerProps={{
                                        sx: { maxHeight: '260px' },
                                    }}
                                    muiSelectAllCheckboxProps={{
                                        color: 'default',
                                        title: '',
                                    }}
                                    muiSelectCheckboxProps={{
                                        color: 'default',
                                        title: '',
                                    }}
                                    localization={localization}
                                />
                            </div>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                {openPlannerMode === 'CREATE' && (
                    <Button
                        style={
                            !projectName || (projectRouteSelection && Object.keys(projectRouteSelection).length === 0)
                                ? { backgroundColor: '#fff' }
                                : { backgroundColor: '#ee8a1d', color: '#fff' }
                        }
                        disabled={!projectName || (projectRouteSelection && Object.keys(projectRouteSelection).length === 0)}
                        onClick={() => handleEnterPlannerDialogClose('CREATE')}
                    >
                        {t('Global.PlannerDialog.Create')}
                    </Button>
                )}
                {openPlannerMode === 'OPEN' && (
                    <Button
                        style={
                            projectSelection && Object.keys(projectSelection).length === 0
                                ? { backgroundColor: '#fff' }
                                : { backgroundColor: '#ee8a1d', color: '#fff' }
                        }
                        disabled={projectSelection && Object.keys(projectSelection).length === 0}
                        onClick={() => handleEnterPlannerDialogClose('OPEN')}
                    >
                        {t('Global.PlannerDialog.Open')}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};
