import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { FormControlLabel, Checkbox } from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import PanoramaWideAngleIcon from '@mui/icons-material/PanoramaWideAngle';
import PanoramaWideAngleSelectIcon from '@mui/icons-material/PanoramaWideAngleSelect';
import { postRoutePlan, getRoutePlans, putRoutePlan, deleteRoutePlan, copyRoutePlan } from '../../_actions/Manage.actions';
import RouteSetTable from './RouteSetTable';
import { useWindowSize } from './ManageController';
import { updateUserActivity } from '../../_actions/Global.actions';
import { DeleteDialogue } from './DeleteDialogue';
import { useTranslation } from 'react-i18next';

const Accordion = styled((props) => (
    <MuiAccordion
        disableGutters
        elevation={0}
        square
        {...props}
    />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: '0px',
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const RoutePlanAccordion = (props) => {
    const {
        openRoutePlan,
        toggleRoutePlan,
        routePlanNameStyle,
        updateServiceDay,
        handleCopyRoutePlan,
        handleEditRoutePlan,
        handleDeleteRoutePlan,
        siteMode,
        handleCopyRoutePlanToDispatcher,
        handleDialogOpen,
        workspace,
        project,
        refreshRoutePlan,
        refreshRouteSet,
        setRefreshRouteSet,
        updateRouteOptimizer,
        updateRouteNumber,
        saveRouteNumber,
        updateNeighborhoodCluster,
        i,
        d,
        m,
    } = props;

    const { t } = useTranslation();

    const sunLabel = t('RoutePlans.RoutePlan.Columns.Sun');
    const satLabel = t('RoutePlans.RoutePlan.Columns.Sat');

    return (
        <Accordion
            key={i}
            expanded={openRoutePlan === d.Id}
            onChange={() => toggleRoutePlan(d.Id)}
            style={{ paddingLeft: '10px' }}
        >
            <AccordionSummary
                aria-controls='panel1d-content'
                id='panel1d-header'
                style={{ width: '100%' }}
            >
                <table
                    style={{ width: '100%', textAlign: 'center' }}
                    className={'MuiTable'}
                >
                    <thead style={i === 0 ? { visibility: 'visible' } : { visibility: 'collapse' }}>
                        <tr>
                            <th>{t('RoutePlans.RoutePlan.Columns.RoutePlan')}</th>
                            <th>{t('RoutePlans.RoutePlan.Columns.Routes')}</th>
                            <th style={{ width: 250, minWidth: 250 }}>{t('RoutePlans.RoutePlan.Columns.ServiceDays')}</th>
                            <th style={{ width: 200 }} />
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td
                                style={routePlanNameStyle}
                                title={d.PlanName}
                            >
                                {d.PlanName}
                            </td>
                            <td>{d.RouteCount === 0 ? '- -' : d.RouteCount}</td>
                            <td>
                                {[
                                    { label: sunLabel, updateServiceDay: 'ServDaySunday', visibility: 'visible' },
                                    { label: 'Mon', updateServiceDay: 'ServDayMonday', visibility: 'collapse' },
                                    { label: 'Tue', updateServiceDay: 'ServDayTuesday', visibility: 'collapse' },
                                    { label: 'Wed', updateServiceDay: 'ServDayWednesday', visibility: 'collapse' },
                                    { label: 'Thu', updateServiceDay: 'ServDayThursday', visibility: 'collapse' },
                                    { label: 'Fri', updateServiceDay: 'ServDayFriday', visibility: 'collapse' },
                                    { label: satLabel, updateServiceDay: 'ServDaySaturday', visibility: 'visible' },
                                ].map((v) => {
                                    return (
                                        <FormControlLabel
                                            key={v.label}
                                            value='top'
                                            control={
                                                <Checkbox
                                                    style={
                                                        d[v.updateServiceDay]
                                                            ? { color: '#ee7c34', padding: '0', width: 30 }
                                                            : { color: '#ccc', padding: '0', width: 30 }
                                                    }
                                                    checked={d[v.updateServiceDay]}
                                                    onClick={(e) => e.stopPropagation()}
                                                    onChange={() => updateServiceDay(d, v.updateServiceDay)}
                                                    icon={<PanoramaWideAngleIcon />}
                                                    checkedIcon={<PanoramaWideAngleSelectIcon />}
                                                />
                                            }
                                            label={<span style={{ fontSize: 12, visibility: v.visibility }}>{v.label}</span>}
                                            labelPlacement='top'
                                            style={{ marginLeft: '0', marginRight: '0' }}
                                        />
                                    );
                                })}
                            </td>
                            <td>
                                <div style={{ whiteSpace: 'nowrap' }}>
                                    <IconButton
                                        title={t('RoutePlans.RoutePlan.Actions.Copy')}
                                        color='primary'
                                        className={'icon_button'}
                                        style={{ color: '#16a8a6', marginLeft: '5px', padding: '0', fontSize: '18px' }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleCopyRoutePlan(d);
                                        }}
                                    >
                                        <i className='RS_COPY' />
                                    </IconButton>
                                    {
                                        //d.PlanName !== defaultName &&
                                        <IconButton
                                            title={t('RoutePlans.RoutePlan.Actions.Edit')}
                                            color='primary'
                                            className={'icon_button'}
                                            style={{ color: '#16a8a6', marginLeft: '5px', padding: '0', fontSize: '18px' }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleEditRoutePlan(d);
                                            }}
                                        >
                                            <i className='RS_EDIT' />
                                        </IconButton>
                                    }
                                    {m.length > 1 && (
                                        <IconButton
                                            title={t('RoutePlans.RoutePlan.Actions.Delete')}
                                            color='primary'
                                            className={'icon_button'}
                                            style={{ color: '#16a8a6', marginLeft: '5px', padding: '0', fontSize: '18px' }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleDeleteRoutePlan(d);
                                            }}
                                        >
                                            <i className='RS_DELETE' />
                                        </IconButton>
                                    )}
                                    {siteMode === 'PLANNER' && (
                                        <IconButton
                                            title={t('RoutePlans.RoutePlan.Actions.CopyRoutePlanToDispatcher')}
                                            color='primary'
                                            className={'icon_button'}
                                            style={{ color: '#16a8a6', marginLeft: '5px', padding: '0', fontSize: '18px' }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleCopyRoutePlanToDispatcher(d);
                                            }}
                                        >
                                            <i className='RS_COPY_TO_DISPATCHER' />
                                        </IconButton>
                                    )}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </AccordionSummary>
            <AccordionDetails>
                <RouteSetTable
                    d={d}
                    workspace={workspace}
                    routePlanId={d.Id}
                    siteMode={siteMode}
                    project={project}
                    enableNeighborhood={d.Zones}
                    GenerateMethod={d.GenerateMethod}
                    NumRoutes={d.NumRoutes}
                    handleDialogOpen={handleDialogOpen}
                    refreshRouteSet={refreshRouteSet}
                    setRefreshRouteSet={setRefreshRouteSet}
                    refreshRoutePlan={refreshRoutePlan.bind(this)}
                    updateRouteOptimizer={updateRouteOptimizer}
                    updateRouteNumber={updateRouteNumber}
                    saveRouteNumber={saveRouteNumber}
                    updateNeighborhoodCluster={updateNeighborhoodCluster}
                    style={{ width: '100%' }}
                />
            </AccordionDetails>
        </Accordion>
    );
};

const RoutePlanTable = (props) => {
    const title = 'Route Plans';
    const defaultName = 'Default';
    const routePlanNameStyle = { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '500px', maxWidth: '500px' };
    const { workspace, siteMode, project, refreshRoutePlanAndRouteSet, setRefreshRoutePlanAndRouteSet, refreshRouteSet, setRefreshRouteSet } = props;
    const size = useWindowSize();
    let routePlanContainerHeight = size.height - 170;

    const [data, setData] = React.useState([]);
    const [editRoutePlanDialog, setEditRoutePlanDialog] = React.useState(false);
    const [deleteRoutePlanDialog, setDeleteRoutePlanDialog] = React.useState(false);
    const [copyRoutePlanDialog, setCopyRoutePlanDialog] = React.useState(false);

    const [mode, setMode] = React.useState('New');
    const [target, setTarget] = React.useState({});
    const [openRoutePlan, setOpenRoutePlan] = React.useState(0);
    const [editingName, setEditingName] = React.useState('');

    const [copyName, setCopyName] = React.useState('');

    const toggleRoutePlan = (e) => {
        setOpenRoutePlan(openRoutePlan !== e ? e : false);
    };

    const handleCopyRoutePlan = (data) => {
        setMode('Copy');
        setTarget(data);
        setEditingName(data.PlanName);
        setEditRoutePlanDialog(true);
    };
    const handleEditRoutePlan = (data) => {
        setMode('Edit');
        setTarget(data);
        setEditRoutePlanDialog(true);
    };
    const handleDeleteRoutePlan = (data) => {
        setMode('Delete');
        setTarget(data);
        setDeleteRoutePlanDialog(true);
    };
    const handleCopyRoutePlanToDispatcher = (data) => {
        setTarget(data);
        setCopyRoutePlanDialog(true);
    };

    const handleEditClose = async (option) => {
        if (option === 'CANCEL') {
            setEditRoutePlanDialog(false);
        } else if (option === 'SAVE') {
            if (mode === 'New' || mode === 'Copy') {
                try {
                    await postRoutePlan(workspace.value, target);
                    let logData = {
                        WorkspaceId: workspace.value,
                        ActionId: 1408,
                        Mode: siteMode,
                        Success: true,
                        Metadata: target.PlanName,
                    };
                    updateUserActivity(logData);

                    setEditRoutePlanDialog(false);
                    setTarget({});

                    const response = await getRoutePlans(workspace.value, siteMode, project);
                    response.data.sort((a, b) => {
                        if (a.PlanName === 'Default') {
                            return -1;
                        } else if (b.PlanName === 'Default') {
                            return 1;
                        } else {
                            return a.PlanName.localeCompare(b.PlanName, 'en', { numeric: true });
                        }
                    });
                    setData(response.data);
                    props.setRefreshRoutePlan(true);
                } catch (e) {
                    let logData = {
                        WorkspaceId: workspace.value,
                        ActionId: 1408,
                        Mode: siteMode,
                        Success: false,
                        Metadata: target.PlanName,
                    };
                    updateUserActivity(logData);

                    const message = e.response.data.Message;
                    const colonIndex = message.indexOf(':', message.indexOf(':') + 1);
                    props.handleDialogOpen(message.substr(0, colonIndex));
                }
            } else if (mode === 'Edit') {
                try {
                    await putRoutePlan(workspace.value, target);

                    let logData = {
                        WorkspaceId: workspace.value,
                        ActionId: 1409,
                        Mode: siteMode,
                        Success: true,
                        Metadata: target.PlanName,
                    };
                    updateUserActivity(logData);

                    setEditRoutePlanDialog(false);
                    setTarget({});

                    const response = await getRoutePlans(workspace.value, siteMode, project);
                    response.data.sort((a, b) => {
                        if (a.PlanName === 'Default') {
                            return -1;
                        } else if (b.PlanName === 'Default') {
                            return 1;
                        } else {
                            return a.PlanName.localeCompare(b.PlanName, 'en', { numeric: true });
                        }
                    });
                    setData(response.data);
                    props.setRefreshRoutePlan(true);
                } catch (e) {
                    let logData = {
                        WorkspaceId: workspace.value,
                        ActionId: 1409,
                        Mode: siteMode,
                        Success: false,
                        Metadata: target.PlanName,
                    };
                    updateUserActivity(logData);

                    const message = e.response.data.Message;
                    const colonIndex = message.indexOf(':', message.indexOf(':') + 1);
                    props.handleDialogOpen(message.substr(0, colonIndex));
                }
            }
        }
    };
    const handleDeleteClose = async (option) => {
        if (option === 'CANCEL') {
            setDeleteRoutePlanDialog(false);
        } else if (option === 'DELETE') {
            try {
                await deleteRoutePlan(workspace.value, target);
                let logData = {
                    WorkspaceId: workspace.value,
                    ActionId: 1410,
                    Mode: siteMode,
                    Success: true,
                    Metadata: target.PlanName,
                };
                updateUserActivity(logData);

                const response = await getRoutePlans(workspace.value, siteMode, project);
                response.data.sort((a, b) => {
                    if (a.PlanName === 'Default') {
                        return -1;
                    } else if (b.PlanName === 'Default') {
                        return 1;
                    } else {
                        return a.PlanName.localeCompare(b.PlanName, 'en', { numeric: true });
                    }
                });
                setData(response.data);
                props.setRefreshRoutePlan(true);
            } catch (e) {
                let logData = {
                    WorkspaceId: workspace.value,
                    ActionId: 1410,
                    Mode: siteMode,
                    Success: false,
                    Metadata: target.PlanName,
                };
                updateUserActivity(logData);

                const message = e.response.data.Message;
                const colonIndex = message.indexOf(':', message.indexOf(':') + 1);
                props.handleDialogOpen(message.substr(0, colonIndex));
            }

            setDeleteRoutePlanDialog(false);
        }
    };

    const handleCopyClose = (option) => {
        if (option === 'CANCEL') {
            setCopyName('');
            setCopyRoutePlanDialog(false);
        } else if (option === 'COPY') {
            let t = { ...target };
            t.PlanName = copyName;
            t.SandboxId = project;
            copyRoutePlan(workspace.value, project, t).then(
                (res) => {
                    props.setRefreshRoutePlan(true);
                    getRoutePlans(workspace.value, siteMode, project).then((response) => {
                        response.data.sort((a, b) => {
                            if (a.PlanName === 'Default') {
                                return -1;
                            } else if (b.PlanName === 'Default') {
                                return 1;
                            } else {
                                return a.PlanName.localeCompare(b.PlanName, 'en', { numeric: true });
                            }
                        });
                        setData(response.data);
                    });
                },
                (err) => {
                    const message = err.response.data.Message;
                    const colonIndex = message.indexOf(':', message.indexOf(':') + 1);
                    props.handleDialogOpen(message.substr(0, colonIndex));
                },
            );

            props.handleDialogOpen('Route plan copied to dispatcher.');
            setTimeout(props.handleDialogClose, 2000);
            setCopyRoutePlanDialog(false);
            setCopyName('');
        }
    };

    const updateForm = (e) => {
        let t = { ...target };
        t[e.target.name] = e.target.value;
        setTarget(t);
    };

    const updateServiceDay = async (d, servDay) => {
        d[servDay] = true;

        try {
            await putRoutePlan(workspace.value, d);
            props.setRefreshRoutePlan(true);
            let logData = {
                WorkspaceId: workspace.value,
                ActionId: 1409,
                Mode: siteMode,
                Success: true,
                Metadata: d.PlanName,
            };
            updateUserActivity(logData);

            const response = await getRoutePlans(workspace.value, siteMode, project);
            response.data.sort((a, b) => {
                if (a.PlanName === 'Default') {
                    return -1;
                } else if (b.PlanName === 'Default') {
                    return 1;
                } else {
                    return a.PlanName.localeCompare(b.PlanName, 'en', { numeric: true });
                }
            });
            setData(response.data);
        } catch (e) {
            let logData = {
                WorkspaceId: workspace.value,
                ActionId: 1409,
                Mode: siteMode,
                Success: false,
                Metadata: d.PlanName,
            };
            updateUserActivity(logData);

            const message = e.response.data.Message;
            const colonIndex = message.indexOf(':', message.indexOf(':') + 1);
            props.handleDialogOpen(message.substr(0, colonIndex));
        }
    };

    const refreshRoutePlan = async () => {
        const response = await getRoutePlans(workspace.value, siteMode, project);
        response.data.sort((a, b) => {
            if (a.PlanName === 'Default') {
                return -1;
            } else if (b.PlanName === 'Default') {
                return 1;
            } else {
                return a.PlanName.localeCompare(b.PlanName, 'en', { numeric: true });
            }
        });
        setData(response.data);
    };

    const updateRouteOptimizer = async (id, option) => {
        let t = data.find((d) => d.Id === id);
        let target = { ...t };
        target.GenerateMethod = option.value;
        if (option.value === 'Full') {
            target.NumRoutes = t.RouteCount;
        } else if (option.value === 'Number') {
            target.Zones = 'Off';
            if (!target.NumRoutes || !parseInt(target.NumRoutes)) {
                target.NumRoutes = t.RouteCount;
            }
        }

        try {
            await putRoutePlan(workspace.value, target);
            props.setRefreshRoutePlan(true);
            let logData = {
                WorkspaceId: workspace.value,
                ActionId: 1409,
                Mode: siteMode,
                Success: true,
                Metadata: target.PlanName,
            };
            updateUserActivity(logData);

            const response = await getRoutePlans(workspace.value, siteMode, project);
            response.data.sort((a, b) => {
                if (a.PlanName === 'Default') {
                    return -1;
                } else if (b.PlanName === 'Default') {
                    return 1;
                } else {
                    return a.PlanName.localeCompare(b.PlanName, 'en', { numeric: true });
                }
            });
            setData(response.data);
        } catch (e) {
            let logData = {
                WorkspaceId: workspace.value,
                ActionId: 1409,
                Mode: siteMode,
                Success: false,
                Metadata: target.PlanName,
            };
            updateUserActivity(logData);
            const message = e.response.data;
            props.handleDialogOpen(JSON.stringify(message));
        }
    };

    const updateRouteNumber = async (id, option) => {
        let d = [...data];
        d.forEach((dt) => {
            if (dt.Id === id) {
                dt.NumRoutes = option;
                dt.GenerateMethod = 'Number';
                dt.Zones = 'Off';
            }
        });
        setData(d);
    };

    const saveRouteNumber = async (id) => {
        let t = data.find((d) => d.Id === id);
        let target = { ...t };
        try {
            await putRoutePlan(workspace.value, target);
            props.setRefreshRoutePlan(true);
            let logData = {
                WorkspaceId: workspace.value,
                ActionId: 1409,
                Mode: siteMode,
                Success: true,
                Metadata: target.PlanName,
            };
            updateUserActivity(logData);

            const response = await getRoutePlans(workspace.value, siteMode, project);
            response.data.sort((a, b) => {
                if (a.PlanName === 'Default') {
                    return -1;
                } else if (b.PlanName === 'Default') {
                    return 1;
                } else {
                    return a.PlanName.localeCompare(b.PlanName, 'en', { numeric: true });
                }
            });
            setData(response.data);
        } catch (e) {
            let logData = {
                WorkspaceId: workspace.value,
                ActionId: 1409,
                Mode: siteMode,
                Success: false,
                Metadata: target.PlanName,
            };
            updateUserActivity(logData);

            const message = e.response.data.Message;
            if (message) {
                const colonIndex = message.indexOf(':', message.indexOf(':') + 1);
                props.handleDialogOpen(message.substr(0, colonIndex));
            }
        }
    };

    const updateNeighborhoodCluster = async (id, status) => {
        let t = data.find((d) => d.Id === id);
        let target = { ...t };
        target.Zones = status ? 'Routes' : 'Off';

        try {
            await putRoutePlan(workspace.value, target);
            props.setRefreshRoutePlan(true);
            let logData = {
                WorkspaceId: workspace.value,
                ActionId: 1409,
                Mode: siteMode,
                Success: true,
                Metadata: target.PlanName,
            };
            updateUserActivity(logData);

            const response = await getRoutePlans(workspace.value, siteMode, project);
            response.data.sort((a, b) => {
                if (a.PlanName === 'Default') {
                    return -1;
                } else if (b.PlanName === 'Default') {
                    return 1;
                } else {
                    return a.PlanName.localeCompare(b.PlanName, 'en', { numeric: true });
                }
            });
            setData(response.data);
        } catch (e) {
            let logData = {
                WorkspaceId: workspace.value,
                ActionId: 1409,
                Mode: siteMode,
                Success: false,
                Metadata: target.PlanName,
            };
            updateUserActivity(logData);

            const message = e.response.data.Message;
            const colonIndex = message.indexOf(':', message.indexOf(':') + 1);
            props.handleDialogOpen(message.substr(0, colonIndex));
        }
    };

    React.useEffect(() => {
        if (workspace && workspace.value !== 0) {
            getRoutePlans(workspace.value, siteMode, project).then(
                (res) => {
                    res.data.sort((a, b) => {
                        if (a.PlanName === 'Default') {
                            return -1;
                        } else if (b.PlanName === 'Default') {
                            return 1;
                        } else {
                            return a.PlanName.localeCompare(b.PlanName, 'en', { numeric: true });
                        }
                    });
                    setData(res.data);
                },
                (err) => {
                    setData([]);
                },
            );
        }
    }, [workspace]);

    React.useEffect(() => {
        if (workspace && workspace.value !== 0) {
            getRoutePlans(workspace.value, siteMode, project).then(
                (res) => {
                    res.data.sort((a, b) => {
                        if (a.PlanName === 'Default') {
                            return -1;
                        } else if (b.PlanName === 'Default') {
                            return 1;
                        } else {
                            return a.PlanName.localeCompare(b.PlanName, 'en', { numeric: true });
                        }
                    });
                    setData(res.data);
                },
                (err) => {
                    setData([]);
                },
            );
        }
    }, [project]);

    React.useEffect(() => {
        if (refreshRoutePlanAndRouteSet && workspace && workspace.value !== 0) {
            getRoutePlans(workspace.value, siteMode, project).then(
                (res) => {
                    res.data.sort((a, b) => {
                        if (a.PlanName === 'Default') {
                            return -1;
                        } else if (b.PlanName === 'Default') {
                            return 1;
                        } else {
                            return a.PlanName.localeCompare(b.PlanName, 'en', { numeric: true });
                        }
                    });
                    setData(res.data);
                },
                (err) => {
                    setData([]);
                },
            );
            setRefreshRoutePlanAndRouteSet(false);
        }
    }, [refreshRoutePlanAndRouteSet]);

    React.useEffect(() => {
        if (!deleteRoutePlanDialog) {
            setTimeout(() => setTarget({}), 1000);
        }
    }, [deleteRoutePlanDialog]);

    return (
        <Grid
            item
            xs={12}
            style={{ zIndex: 100, overflowX: 'hidden', overflowY: 'auto' }}
        >
            <div style={{ maxWidth: '100%', padding: '5px', height: routePlanContainerHeight }}>
                {data.map((d, i, m) => {
                    return (
                        <RoutePlanAccordion
                            key={i}
                            i={i}
                            d={d}
                            m={m}
                            workspace={workspace}
                            siteMode={siteMode}
                            project={project}
                            openRoutePlan={openRoutePlan}
                            toggleRoutePlan={toggleRoutePlan}
                            routePlanNameStyle={routePlanNameStyle}
                            updateServiceDay={updateServiceDay}
                            handleCopyRoutePlan={handleCopyRoutePlan}
                            handleEditRoutePlan={handleEditRoutePlan}
                            handleDeleteRoutePlan={handleDeleteRoutePlan}
                            handleCopyRoutePlanToDispatcher={handleCopyRoutePlanToDispatcher}
                            refreshRoutePlan={refreshRoutePlan}
                            handleDialogOpen={props.handleDialogOpen}
                            updateRouteOptimizer={updateRouteOptimizer}
                            updateRouteNumber={updateRouteNumber}
                            saveRouteNumber={saveRouteNumber}
                            updateNeighborhoodCluster={updateNeighborhoodCluster}
                            refreshRouteSet={refreshRouteSet}
                            setRefreshRouteSet={setRefreshRouteSet}
                        />
                    );
                })}
            </div>
            <CopyRoutePlanDialog
                copyRoutePlanDialog={copyRoutePlanDialog}
                mode={mode}
                copyName={copyName}
                setCopyName={setCopyName}
                handleCopyClose={handleCopyClose}
            />
            <EditRoutePlanDialog
                editRoutePlanDialog={editRoutePlanDialog}
                mode={mode}
                target={target}
                editingName={editingName}
                updateForm={updateForm}
                handleEditClose={handleEditClose}
            />
            <DeleteDialogue
                deleteDriverDialog={deleteRoutePlanDialog}
                mode={mode}
                title={'RoutePlan'}
                target={target}
                handleDeleteClose={handleDeleteClose}
            />
        </Grid>
    );
};

export default RoutePlanTable;

const CopyRoutePlanDialog = (props) => {
    const { copyRoutePlanDialog, mode, copyName, setCopyName, handleCopyClose } = props;
    const { t } = useTranslation();
    return (
        <Dialog
            open={copyRoutePlanDialog}
            maxWidth={'sm'}
            fullWidth={true}
        >
            <DialogTitle>
                {t(`RoutePlans.RoutePlan.Actions.${mode}`)} {t(`RoutePlans.RoutePlan.Title`)}
            </DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin='dense'
                    id='PlanName'
                    name='PlanName'
                    label={t('RoutePlans.RoutePlan.Form.RoutePlanName')}
                    type='text'
                    fullWidth
                    variant='standard'
                    value={copyName}
                    onChange={(event) => {
                        setCopyName(event.target.value);
                    }}
                />
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                    style={{ backgroundColor: '#ee8a1d', color: '#fff' }}
                    onClick={() => handleCopyClose('CANCEL')}
                >
                    {t('Common.Cancel')}
                </Button>
                <Button
                    disabled={!copyName}
                    style={!copyName ? { backgroundColor: '#fff' } : { backgroundColor: '#16a8a6', color: '#fff' }}
                    onClick={() => handleCopyClose('COPY')}
                >
                    {t('Common.OK')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const EditRoutePlanDialog = (props) => {
    const { editRoutePlanDialog, mode, target, editingName, updateForm, handleEditClose } = props;
    const { t } = useTranslation();
    return (
        <Dialog
            open={editRoutePlanDialog}
            maxWidth={'sm'}
            fullWidth={true}
        >
            <DialogTitle>
                {t(`RoutePlans.RoutePlan.Actions.${mode}`)} {t(`RoutePlans.RoutePlan.Title`)}
            </DialogTitle>
            <DialogContent>
                <Grid
                    container
                    spacing={1}
                >
                    <Grid
                        item
                        xs={12}
                    >
                        {mode === 'New' || mode === 'Copy' ? (
                            <TextField
                                autoFocus
                                margin='dense'
                                id='PlanName'
                                name='PlanName'
                                label={t('RoutePlans.RoutePlan.Form.RoutePlanName')}
                                type='text'
                                fullWidth
                                variant='standard'
                                onChange={updateForm}
                            />
                        ) : (
                            <TextField
                                autoFocus
                                margin='dense'
                                id='PlanName'
                                name='PlanName'
                                label={t('RoutePlans.RoutePlan.Form.RoutePlanName')}
                                type='text'
                                fullWidth
                                variant='standard'
                                value={target.PlanName}
                                onChange={updateForm}
                            />
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                    style={{ backgroundColor: '#ee8a1d', color: '#fff' }}
                    onClick={() => handleEditClose('CANCEL')}
                >
                    {t('Common.Cancel')}
                </Button>
                {((mode === 'Copy' && target.PlanName && target.PlanName !== editingName) ||
                    //(mode === 'Edit' && target.PlanName && target.PlanName !== defaultName)
                    (mode === 'Edit' && target.PlanName)) && (
                    <Button
                        style={{ backgroundColor: '#16a8a6', color: '#fff' }}
                        onClick={() => handleEditClose('SAVE')}
                    >
                        {t('Common.Save')}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};
