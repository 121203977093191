import React from 'react';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import DialogActions from '@mui/material/DialogActions';
import TextArea from 'devextreme-react/text-area';
import TextBox from 'devextreme-react/text-box';
import { RODialog, RODialogLabel, ROOrangeButton, ROGreenButton } from '../../../common';
import { useTranslation } from 'react-i18next';

export const AddEditGroupsDialog = (props) => {
    const { groupMode, open, handleChange, groupTarget, handleClick } = props;
    const { t } = useTranslation();
    return (
        <RODialog
            maxWidth='xs'
            fullWidth={true}
            open={open}
            dialogTitle={`${groupMode === 'New' ? t('AccountManagement.Groups.Actions.AddGroup') : t('AccountManagement.Groups.Actions.EditGroup')}`}
            dialogContent={
                <DialogContent>
                    <Grid
                        container
                        spacing={'10px'}
                        style={{ paddingTop: '10px' }}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <RODialogLabel>{t('AccountManagement.Groups.Form.GroupName')} *</RODialogLabel>
                            <TextBox
                                name='Name'
                                inputAttr={{ autocomplete: 'new-name' }}
                                defaultValue={groupTarget.Name}
                                onValueChanged={(e) => {
                                    handleChange('Name', e.value);
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                        >
                            <RODialogLabel>{t('AccountManagement.Groups.Form.GroupDescription')}</RODialogLabel>
                            <TextArea
                                name='Description'
                                height={60}
                                defaultValue={groupTarget.Description}
                                onValueChanged={(e) => {
                                    handleChange('Description', e.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
            }
            dialogActions={
                <DialogActions style={{ justifyContent: 'right' }}>
                    {groupTarget.Name && (
                        <ROGreenButton
                            onClick={() => {
                                handleClick('SAVE');
                            }}
                        >
                            {' '}
                            {t('Common.Save')}
                        </ROGreenButton>
                    )}
                    <ROOrangeButton
                        onClick={() => {
                            handleClick('CANCEL');
                        }}
                    >
                        {t('Common.Cancel')}
                    </ROOrangeButton>
                </DialogActions>
            }
        />
    );
};
