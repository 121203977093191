import React from 'react';
import DialogContentText from '@mui/material/DialogContentText';
import { RODialog } from '../../../common';
import { useTranslation } from 'react-i18next';

export const DeleteRoles = (props) => {
    const { open, handleClick, roleTarget } = props;
    const { t } = useTranslation();
    return (
        <RODialog
            maxWidth='sm'
            fullWidth={true}
            open={open}
            dialogTitle={t('AccountManagement.Roles.Actions.DeleteRole')}
            cancelText={t('Common.Cancel')}
            confirmText={t('Common.Yes')}
            handleCancel={() => {
                handleClick('CANCEL');
            }}
            handleConfirm={() => {
                handleClick('DELETE');
            }}
        >
            <DialogContentText>{t('AccountManagement.Roles.Messages.DeleteConfirm', { name: roleTarget.RoleName })}</DialogContentText>
        </RODialog>
    );
};
