import React from 'react';
import DataGrid, { Column, Sorting, Pager, FilterRow, HeaderFilter, SearchPanel, Toolbar, Item, Scrolling } from 'devextreme-react/data-grid';
import DxButton from 'devextreme-react/button';
import {
    getRoles,
    getAssignableFunctions,
    getAssignableRoles,
    getAssignableGroups,
    getRoleAssignedFunctions,
    getRoleAssignedRoles,
    getRoleAssignedGroups,
    addRole,
    updateRole,
    deleteRole,
} from '../../../_actions/AccountManagement.actions';
import { updateUserActivity } from '../../../_actions/Global.actions';
import { GlobalROContext } from '../../common';
import { getErrorMessage } from '../../../_helpers/common';
import { config } from '../../../_helpers/config';
import { AddEditRolesDialog } from './Dialogs/AddEdit';
import { DeleteRoles } from './Dialogs/Delete';
import { useTranslation } from 'react-i18next';

const Roles = (props) => {
    const refRoleListGrid = React.useRef();
    const refRoleList = React.useRef();
    const refGroupList = React.useRef();
    const { t } = useTranslation();
    const refSelFunctionSelectEvent = React.useRef();
    const refSelRoleSelectEvent = React.useRef();
    const refSelGroupSelectEvent = React.useRef();
    const [assignableRoles, setAssignableRoles] = React.useState(null);
    const [assignableGroups, setAssignableGroups] = React.useState(null);
    const [roleData, setRoleData] = React.useState(null);
    const [assignableFunctions, setAssignableFunctions] = React.useState(null);
    const [expandedFunctionKeys, setExpandedFunctionKeys] = React.useState([]);
    const [selFunctionKeys, setSelFunctionKeys] = React.useState([]);
    const [hasAccountManagementAccess, setHasAccountManagementAccess] = React.useState(false);
    const [selRoleKeys, setSelRoleKeys] = React.useState([]);
    const [selGroupKeys, setSelGroupKeys] = React.useState([]);
    const [showEditRoleDialog, setShowEditRoleDialog] = React.useState(false);
    const [showDeleteRoleDialog, setShowDeleteRoleDialog] = React.useState(false);
    const [roleMode, setRoleMode] = React.useState('');
    const [roleTarget, setRoleTarget] = React.useState({});
    const { showApiROErrorDialog, setShowApiROErrorDialog } = React.useContext(GlobalROContext);

    const populateRoles = async () => {
        const rolesResponse = await getRoles();
        if (rolesResponse && rolesResponse.data && rolesResponse.data.length > 0) {
            setRoleData(rolesResponse.data);
        } else {
            setRoleData([]);
        }
    };

    React.useEffect(() => {
        populateRoles();
    }, []);

    const handleFunctionRowClick = (e) => {
        let selFuncKeys = [...selFunctionKeys];
        if (selFuncKeys.includes(e.data.id)) {
            if (e.data.parent !== 0) {
                let otherSubFunctionsSelected = false;
                let subFunctions = assignableFunctions.filter((item) => item.parent === e.data.parent);
                for (let i = 0; i < subFunctions.length; i++) {
                    if (selFuncKeys.includes(subFunctions[i].id) && subFunctions[i].id !== e.data.id) {
                        otherSubFunctionsSelected = true;
                        break;
                    }
                }
                if (otherSubFunctionsSelected) {
                    //Unselect function
                    selFuncKeys = selFuncKeys.filter((item) => item != e.data.id);
                } else {
                    //Unselect function then unselect parent function if no sub functions are selected
                    selFuncKeys = selFuncKeys.filter((item) => item !== e.data.id && item !== e.data.parent);
                }
            } else {
                let subFuncKeys = [];
                let subFunctions = assignableFunctions.filter((item) => item.parent === e.data.id);
                subFunctions.forEach((it) => {
                    subFuncKeys.push(it.id);
                });
                //Unselect function and sub functions when parent function is unselected
                selFuncKeys = selFuncKeys.filter((item) => item != e.data.id && !subFuncKeys.includes(item));
            }
        } else {
            selFuncKeys.push(e.data.id);
            if (e.data.parent !== 0) {
                //Select parent function if not selected
                if (!selFuncKeys.includes(e.data.parent)) {
                    selFuncKeys.push(e.data.parent);
                }
            } else {
                //Select sub functions when parent function is selected
                let subFuncKeys = assignableFunctions.filter((item) => item.parent === e.data.id);
                subFuncKeys.forEach((it) => {
                    selFuncKeys.push(it.id);
                });
            }
        }
        refSelFunctionSelectEvent.current.value = 'RowClick';
        setSelFunctionKeys(selFuncKeys);
        checkAccountManagementAccess(selFuncKeys);
    };

    React.useEffect(() => {
        if (refRoleList && refRoleList.current && refRoleList.current.instance) {
            refRoleList.current.instance.refresh();
        }
        if (refGroupList && refGroupList.current && refGroupList.current.instance) {
            refGroupList.current.instance.refresh();
        }
    }, [hasAccountManagementAccess, selRoleKeys]);

    const updateRoleForm = (field, value) => {
        let t = { ...roleTarget };
        t[field] = value;
        setRoleTarget(t);
    };

    const handleEditRole = async (mode, data) => {
        try {
            if (refRoleListGrid && refRoleListGrid.current && refRoleListGrid.current.instance) {
                refRoleListGrid.current.instance.beginCustomLoading();
            }
            const assignFunctions = await getAssignableFunctions();
            let functions = [];
            let expandKeys = [];
            let parentFunctions = {};
            assignFunctions.data.forEach((item) => {
                if (
                    item.FunctionName !== '' &&
                    !(config.FUNCTIONS_TO_HIDE_ROLE.includes(item.FunctionName) || config.FUNCTIONS_TO_HIDE_ROLE.includes(item.SubFunctionName))
                ) {
                    if (item.SubFunctionName === '') {
                        //TreeList does not support a key value of 0. Replacing to 1 at load. Replace 1 with 0 while saving.
                        functions.push({ id: item.FunctionID === '0' ? 1 : parseInt(item.FunctionID), text: item.FunctionName, parent: 0 });
                        if (!Object.keys(parentFunctions).includes(item.FunctionName)) {
                            parentFunctions[item.FunctionName] = parseInt(item.FunctionID);
                        }
                    } else {
                        if (!expandKeys.includes(parentFunctions[item.FunctionName])) {
                            expandKeys.push(parentFunctions[item.FunctionName]);
                        }
                        functions.push({ id: parseInt(item.FunctionID), text: item.SubFunctionName, parent: parentFunctions[item.FunctionName] });
                    }
                }
            });
            setAssignableFunctions(functions);
            setExpandedFunctionKeys(expandKeys);
            const assignRoles = await getAssignableRoles();
            let roles = [];
            assignRoles.data.forEach((item) => {
                roles.push({ id: item.RoleID, text: item.RoleName });
            });
            setAssignableRoles(roles);
            const assignGroups = await getAssignableGroups();
            let groups = [];
            assignGroups.data.forEach((item) => {
                groups.push({ id: item.Id, text: item.Name });
            });
            setAssignableGroups(groups);
        } catch (e) {
            console.log(e);
        }

        setRoleMode(mode);
        if (mode === 'New') {
            setSelFunctionKeys([1]);
            setSelRoleKeys([]);
            setSelGroupKeys([]);
            setHasAccountManagementAccess(false);
        } else {
            loadSavedFunctionsRolesGroups(data.RoleID);
        }
        setRoleTarget(data);
        setShowEditRoleDialog(true);
        if (refRoleListGrid && refRoleListGrid.current && refRoleListGrid.current.instance) {
            refRoleListGrid.current.instance.endCustomLoading();
        }
    };

    const loadSavedFunctionsRolesGroups = async (roleId) => {
        try {
            let assignedFunctionsResp = await getRoleAssignedFunctions(roleId);
            if (assignedFunctionsResp && assignedFunctionsResp.data && assignedFunctionsResp.data.length > 0) {
                let assignedFunctions = [];
                let hasAccMgmtAccess = false;
                assignedFunctionsResp.data.forEach((item) => {
                    //TreeList does not support a key value of 0. Replacing to 1 at load. Replace 1 with 0 while saving.
                    assignedFunctions.push(item.FunctionID === '0' ? 1 : parseInt(item.FunctionID));
                    if (item.FunctionID === '904') {
                        hasAccMgmtAccess = true;
                    }
                });
                if (hasAccMgmtAccess) setHasAccountManagementAccess(true);
                else setHasAccountManagementAccess(false);
                setSelFunctionKeys(assignedFunctions);
            }
            let assignedRolesResp = await getRoleAssignedRoles(roleId);
            if (assignedRolesResp && assignedRolesResp.data && assignedRolesResp.data.length > 0) {
                let assignedRoles = [];
                assignedRolesResp.data.forEach((item) => {
                    assignedRoles.push(item.RoleID);
                });
                setSelRoleKeys(assignedRoles);
            }
            let assignedGroupsResp = await getRoleAssignedGroups(roleId);
            if (assignedGroupsResp && assignedGroupsResp.data && assignedGroupsResp.data.length > 0) {
                let assignedGroups = [];
                assignedGroupsResp.data.forEach((item) => {
                    assignedGroups.push(item.Id);
                });
                setSelGroupKeys(assignedGroups);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleDeleteRole = (data) => {
        setRoleMode('Delete');
        setRoleTarget(data);
        setShowDeleteRoleDialog(true);
    };

    const handleEditRoleClose = async (option) => {
        if (option === 'CANCEL') {
            setShowEditRoleDialog(false);
            setRoleTarget({});
        } else if (option === 'SAVE') {
            let userInfo = JSON.parse(window.sessionStorage['userInfo']);
            //TreeList does not support a key value of 0. Replaced to 1 at load. Replacing 1 with 0 while saving.
            let selFuncKeys = [...selFunctionKeys];
            if (selFuncKeys.includes(1)) {
                selFuncKeys = selFuncKeys.filter((item) => item != 1);
                selFuncKeys.push(0);
            }
            let assignedFuncIDs = selFuncKeys.join(',');
            let postData = {
                ClientID: userInfo.ClientID,
                RoleName: roleTarget.RoleName,
                RoleDescription: roleTarget.RoleDescription,
                AssignableFunctionIDs: assignedFuncIDs,
            };
            if (selRoleKeys.length > 0) {
                postData.AssignableRoleIDs = selRoleKeys.join(',');
            }
            if (selGroupKeys.length > 0) {
                postData.AssignableGroupIDs = selGroupKeys.join(',');
            }
            let err;
            let ActionId;
            try {
                if (roleMode === 'New') {
                    ActionId = 911;
                    await addRole(postData);
                } else if (roleMode === 'Edit') {
                    ActionId = 913;
                    await updateRole(roleTarget.RoleID, postData);
                }

                setShowEditRoleDialog(false);
                setRoleTarget({});
                populateRoles();
            } catch (e) {
                err = e;
                if (!showApiROErrorDialog) {
                    setShowApiROErrorDialog(getErrorMessage(err));
                }
            } finally {
                let logData = {
                    ActionId: ActionId,
                    Success: err ? false : true,
                    Metadata: roleTarget.RoleName,
                };
                updateUserActivity(logData);
            }
        }
    };

    const handleDeleteRoleClose = async (option) => {
        if (option === 'CANCEL') {
            setShowDeleteRoleDialog(false);
            setRoleTarget({});
        } else if (option === 'DELETE') {
            let err;
            try {
                await deleteRole(roleTarget.RoleID);
                setShowDeleteRoleDialog(false);
                setRoleTarget({});
                populateRoles();
            } catch (e) {
                err = e;
                if (!showApiROErrorDialog) {
                    setShowApiROErrorDialog(getErrorMessage(err));
                }
            } finally {
                let logData = {
                    ActionId: 912,
                    Success: err ? false : true,
                    Metadata: roleTarget.RoleName,
                };
                updateUserActivity(logData);
            }
        }
    };

    const checkAccountManagementAccess = (keys) => {
        if (keys.includes(904)) {
            setHasAccountManagementAccess(true);
        } else {
            setSelRoleKeys([]);
            setSelGroupKeys([]);
            setHasAccountManagementAccess(false);
        }
    };

    const getFunctionListSelectAllValue = () => {
        if (selFunctionKeys.length === 0) {
            return false;
        } else if (selFunctionKeys.length === assignableFunctions.length) {
            return true;
        } else {
            return null;
        }
    };

    const getRoleListSelectAllValue = () => {
        if (selRoleKeys.length === 0) {
            return false;
        } else if (selRoleKeys.length === assignableRoles.length) {
            return true;
        } else {
            return null;
        }
    };

    const getGroupListSelectAllValue = () => {
        if (selGroupKeys.length === 0) {
            return false;
        } else if (selGroupKeys.length === assignableGroups.length) {
            return true;
        } else {
            return null;
        }
    };

    return (
        <>
            <DataGrid
                height={props.windowDimensions.height - 275}
                dataSource={roleData}
                keyExpr='RoleID'
                showBorders={true}
                showColumnLines={true}
                showRowLines={true}
                allowColumnResizing={true}
                columnResizingMode={'nextColumn'}
                columnMinWidth={50}
                columnAutoWidth={true}
                ref={refRoleListGrid}
            >
                <SearchPanel
                    visible={true}
                    width={240}
                    placeholder={t('Common.Search')}
                />
                <HeaderFilter visible={false} />
                <FilterRow
                    visible={false}
                    applyFilter={true}
                />
                <Pager
                    visible={true}
                    showInfo={true}
                />
                <Scrolling mode='virtual' />
                <Sorting mode='multiple' />
                <Toolbar>
                    <Item location='before'>
                        <DxButton
                            hint={t('AccountManagement.Roles.Actions.AddRole')}
                            icon='add'
                            text={t('AccountManagement.Roles.Actions.AddRole')}
                            onClick={(e) => {
                                handleEditRole('New', {});
                            }}
                        />
                    </Item>
                    <Item
                        location='after'
                        name='searchPanel'
                    />
                </Toolbar>
                <Column
                    dataField='RoleName'
                    caption={t('AccountManagement.Roles.Columns.RoleName')}
                ></Column>
                <Column
                    dataField='RoleDescription'
                    caption={t('AccountManagement.Roles.Columns.Description')}
                ></Column>
                <Column
                    dataField='NumberOfUsers'
                    caption={t('AccountManagement.Roles.Columns.NumberOfUsers')}
                ></Column>
                <Column
                    type='buttons'
                    width='80px'
                    cellRender={(e) => {
                        return (
                            <div>
                                <DxButton
                                    hint={t('AccountManagement.Roles.Actions.EditRole')}
                                    icon='edit'
                                    onClick={() => {
                                        handleEditRole('Edit', e.data);
                                    }}
                                />
                                &nbsp;
                                <DxButton
                                    hint={t('AccountManagement.Roles.Actions.DeleteRole')}
                                    icon='trash'
                                    type='danger'
                                    disabled={e.data.NumberOfUsers > 0 ? true : false}
                                    onClick={() => {
                                        handleDeleteRole(e.data);
                                    }}
                                />
                            </div>
                        );
                    }}
                ></Column>
            </DataGrid>

            {showEditRoleDialog && (
                <AddEditRolesDialog
                    showEditRoleDialog={showEditRoleDialog}
                    roleMode={roleMode}
                    assignableRoles={assignableRoles}
                    assignableGroups={assignableGroups}
                    roleTarget={roleTarget}
                    loadSavedFunctionsRolesGroups={loadSavedFunctionsRolesGroups}
                    updateRoleForm={updateRoleForm}
                    refSelFunctionSelectEvent={refSelFunctionSelectEvent}
                    refSelRoleSelectEvent={refSelRoleSelectEvent}
                    refSelGroupSelectEvent={refSelGroupSelectEvent}
                    refRoleList={refRoleList}
                    refGroupList={refGroupList}
                    assignableFunctions={assignableFunctions}
                    expandedFunctionKeys={expandedFunctionKeys}
                    selFunctionKeys={selFunctionKeys}
                    selRoleKeys={selRoleKeys}
                    selGroupKeys={selGroupKeys}
                    setSelFunctionKeys={setSelFunctionKeys}
                    setSelRoleKeys={setSelRoleKeys}
                    setSelGroupKeys={setSelGroupKeys}
                    handleEditRoleClose={handleEditRoleClose}
                    hasAccountManagementAccess={hasAccountManagementAccess}
                    handleFunctionRowClick={handleFunctionRowClick}
                    checkAccountManagementAccess={checkAccountManagementAccess}
                    getFunctionListSelectAllValue={getFunctionListSelectAllValue}
                    getRoleListSelectAllValue={getRoleListSelectAllValue}
                    getGroupListSelectAllValue={getGroupListSelectAllValue}
                />
            )}

            {showDeleteRoleDialog && (
                <DeleteRoles
                    open={showDeleteRoleDialog}
                    handleClick={handleDeleteRoleClose}
                    roleTarget={roleTarget}
                />
            )}
        </>
    );
};

export default Roles;
