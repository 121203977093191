import React from 'react';
import DataSource from 'devextreme/data/data_source';
import DataGrid, { Column, Scrolling, Sorting, Pager, FilterRow, HeaderFilter, SearchPanel, Toolbar, Item } from 'devextreme-react/data-grid';
import DxButton from 'devextreme-react/button';
import { useTranslation } from 'react-i18next';
import { UserDataPrivilegesDialog } from './Dialogs/UserDataPrivileges';
import { AddEditUsersDialog } from './Dialogs/AddEdit';
import { DeleteUsers } from './Dialogs/Delete';
import { GlobalROContext } from '../../common';
import { config } from '../../../_helpers/config';
import { getErrorMessage } from '../../../_helpers/common';
import { getWorkspaceList, getRouteList, updateUserActivity } from '../../../_actions/Global.actions';
import {
    getUsers,
    addUser,
    updateUser,
    deleteUser,
    getAssignableRoles,
    getAssignableGroups,
    getRoleAssignedFunctions,
    getUserDataForFunction,
    addUserDataForFunction,
    replicateUserDataForFunction,
    deleteUserData,
} from '../../../_actions/AccountManagement.actions';

const Users = (props) => {
    const { t } = useTranslation();
    const refUserListGrid = React.useRef();
    const [userData, setUserData] = React.useState(null);
    const [showEditUserDialog, setShowEditUserDialog] = React.useState(false);
    const [workspaceList, setWorkspaceList] = React.useState([]);
    const [workspaceRouteList, setWorkspaceRouteList] = React.useState({});
    const [savedUserFunctionData, setSavedUserFunctionData] = React.useState([]);
    const [showEditUserPrivilegesDialog, setShowEditUserPrivilegesDialog] = React.useState(false);
    const [showDeleteUserDialog, setShowDeleteUserDialog] = React.useState(false);
    const [userMode, setUserMode] = React.useState('');
    const [userTarget, setUserTarget] = React.useState({});
    const [userPopoverVisible, setUserPopoverVisible] = React.useState(false);
    const [editUserPasswordVisible, setEditUserPasswordVisible] = React.useState(false);
    const [passwordMode, setPasswordMode] = React.useState('password');
    const [confirmPasswordMode, setConfirmPasswordMode] = React.useState('password');
    const [assignableRoles, setAssignableRoles] = React.useState(null);
    const [assignableGroups, setAssignableGroups] = React.useState(null);
    const [userPrivilegesTarget, setUserPrivilegesTarget] = React.useState({});
    const [userPrivilegesFunctions, setUserPrivilegesFunctions] = React.useState([]);
    const [userPrivilegesReplicateFunctions, setUserPrivilegesReplicateFunctions] = React.useState([]);
    const [canAccessAll, setCanAccessAll] = React.useState(false);
    const { showApiROErrorDialog, setShowApiROErrorDialog } = React.useContext(GlobalROContext);

    const userPrivilegesGroupFunctionsData = new DataSource({
        store: {
            type: 'array',
            data: userPrivilegesFunctions,
            key: 'id',
        },
        group: 'groupName',
    });
    const userPrivilegesReplicateGroupFunctionsData = new DataSource({
        store: {
            type: 'array',
            data: userPrivilegesReplicateFunctions,
            key: 'id',
        },
        group: 'groupName',
    });

    const populateUsers = async () => {
        const usersResponse = await getUsers();
        if (usersResponse && usersResponse.data && usersResponse.data.length > 0) {
            let usersData = usersResponse.data;
            usersData.forEach((item) => {
                item.RoleId = parseInt(item.RoleId);
            });
            setUserData(usersData);
        } else {
            setUserData([]);
        }
    };

    React.useEffect(() => {
        populateUsers();
    }, []);

    const updateUserForm = (field, value) => {
        let t = { ...userTarget };
        t[field] = value;
        setUserTarget(t);
    };

    const handleEditUser = async (mode, data) => {
        try {
            const assignRoles = await getAssignableRoles();
            let roles = [];
            assignRoles.data.forEach((item) => {
                roles.push({ id: item.RoleID, text: item.RoleName });
            });
            setAssignableRoles(roles);
            const assignGroups = await getAssignableGroups();
            let groups = [];
            assignGroups.data.forEach((item) => {
                groups.push({ id: item.Id, text: item.Name });
            });
            setAssignableGroups(groups);
        } catch (e) {
            console.log(e);
        }
        setUserMode(mode);
        if (mode === 'New') {
            setEditUserPasswordVisible(true);
        } else {
            setEditUserPasswordVisible(false);
        }
        setUserTarget(data);
        setShowEditUserDialog(true);
    };

    const handleEditUserPrivileges = async (data) => {
        try {
            const assignFunctions = await getRoleAssignedFunctions(data.RoleId);
            let functions = [];
            assignFunctions.data.forEach((item) => {
                if (!config.FUNCTIONS_TO_HIDE_FROM_PRIVILEGE_ASSIGN.includes(parseInt(item.FunctionID))) {
                    if (item.SubFunctionName === '') {
                        //TreeList does not support a key value of 0. Replacing to 1 at load. Replace 1 with 0 while saving.
                        functions.push({
                            id: item.FunctionID === '0' ? 1 : parseInt(item.FunctionID),
                            functionName: item.FunctionName,
                            groupName: item.FunctionName,
                        });
                    } else {
                        functions.push({ id: parseInt(item.FunctionID), functionName: item.SubFunctionName, groupName: item.FunctionName });
                    }
                }
            });
            setUserPrivilegesFunctions(functions);
            setUserPrivilegesReplicateFunctions([]);
            let workspaceResp = await getWorkspaceList();
            let wsList = workspaceResp.data;
            let wsRouteList = {};
            wsList.forEach(async (item) => {
                item.Status = 'Unselected';
                if (item.Id > 0) {
                    const res = await getRouteList(item.Id, 0); //Routes for all service days
                    let wsRteLst = res.data;
                    wsRteLst.forEach((item) => {
                        item.Status = 'Unselected';
                    });
                    wsRouteList[item.Id] = wsRteLst;
                }
            });
            wsList.sort((a, b) => {
                return a.Name.localeCompare(b.Name, 'en', { numeric: true });
            });
            setWorkspaceList(wsList);
            setWorkspaceRouteList(wsRouteList);
            setUserPrivilegesTarget(data);
            setShowEditUserPrivilegesDialog(true);
        } catch (e) {
            console.log(e);
        }
    };

    const handleDeleteUser = (data) => {
        setUserMode('Delete');
        setUserTarget(data);
        setShowDeleteUserDialog(true);
    };

    const validateUserForm = () => {
        let valid = true;
        if (!userTarget.UserName || userTarget.UserName.trim().length == 0) {
            valid = false;
        }
        if (
            editUserPasswordVisible &&
            (!userTarget.Password ||
                !userTarget.Password.match(/[A-Z]/) ||
                !userTarget.Password.match(/[a-z]/) ||
                !userTarget.Password.match(/\d/) ||
                !userTarget.Password.match(/[$-/:-?{-~!"^_`@#\[\]\\]/) ||
                userTarget.Password.length < 8 ||
                userTarget.Password.length > 16)
        ) {
            valid = false;
        }
        if (
            editUserPasswordVisible &&
            (!userTarget.ConfirmPassword || userTarget.ConfirmPassword.trim().length == 0 || userTarget.Password != userTarget.ConfirmPassword)
        ) {
            valid = false;
        }
        if (!userTarget.Email || userTarget.Email.trim().length == 0) {
            valid = false;
        }

        if (!userTarget.RoleId) {
            valid = false;
        }

        return valid;
    };

    const handleEditUserClose = async (option) => {
        if (option === 'CANCEL') {
            setShowEditUserDialog(false);
            setUserTarget({});
        } else if (option === 'SAVE') {
            if (validateUserForm()) {
                let ActionId;
                let err;
                try {
                    let postData = {
                        UserName: userTarget.UserName,
                        PreferredUsername: userTarget.PreferredUsername,
                        Email: userTarget.Email,
                        FirstName: userTarget.FirstName,
                        LastName: userTarget.LastName,
                        RoleId: userTarget.RoleId,
                    };
                    if (userMode === 'New') {
                        ActionId = 904;
                        postData.UserPassword = userTarget.Password;
                        if (userTarget.GroupId) {
                            postData.GroupId = userTarget.GroupId;
                        }
                        await addUser(postData);
                    } else if (userMode === 'Edit') {
                        ActionId = 906;
                        if (userTarget.Password) {
                            postData.UserPassword = userTarget.Password;
                        }
                        if (userTarget.GroupId) {
                            postData.GroupId = userTarget.GroupId;
                        }
                        await updateUser(userTarget.UserID, postData);
                    }
                    setShowEditUserDialog(false);
                    setUserTarget({});
                    populateUsers();
                } catch (e) {
                    err = e;
                    if (!showApiROErrorDialog) {
                        setShowApiROErrorDialog(getErrorMessage(err));
                    }
                } finally {
                    let logData = {
                        ActionId,
                        Success: err ? false : true,
                        Metadata: userTarget.UserName,
                    };
                    updateUserActivity(logData);
                }
            }
        }
    };

    const handleEditUserPrivilegesClose = async (option) => {
        if (option === 'CANCEL') {
            setShowEditUserPrivilegesDialog(false);
            setUserPrivilegesTarget({});
        } else if (option === 'SAVE') {
            try {
                if (savedUserFunctionData) {
                    //delete user data for function ID
                    savedUserFunctionData.forEach((item) => {
                        deleteUserData(userPrivilegesTarget.UserID, item.ID);
                    });
                }
                //Add user data for function ID
                let userDataWorkspaces = [];
                let userDataRoutes = {};
                let selectedWorkspaces = workspaceList.filter((obj) => {
                    return obj.Status === 'Selected';
                });
                if (selectedWorkspaces.length == workspaceList.length) {
                    userDataWorkspaces.push('ALL WORKSPACES');
                    userDataRoutes['ALL WORKSPACES'] = '';
                } else {
                    if (selectedWorkspaces.length > 0) {
                        selectedWorkspaces.forEach((item) => {
                            userDataWorkspaces.push(item.Id);
                            let selectedRoutes = workspaceRouteList[item.Id].filter((obj) => {
                                return obj.Status === 'Selected';
                            });
                            if (selectedRoutes.length == workspaceRouteList[item.Id].length) {
                                userDataRoutes[item.Id.toString()] = 'ALL ROUTES';
                            } else {
                                let userDataWorkspaceRoutes = [];
                                selectedRoutes.forEach((udwRtItem) => {
                                    userDataWorkspaceRoutes.push(udwRtItem.Id);
                                });
                                userDataRoutes[item.Id.toString()] = userDataWorkspaceRoutes.join(',');
                            }
                        });
                    }
                }
                userDataWorkspaces.forEach((workspaceId) => {
                    let postData = {
                        UserID: userPrivilegesTarget.UserID,
                        UserName: userPrivilegesTarget.UserName,
                        FunctionID: userPrivilegesTarget.FunctionID,
                        Workspaces: workspaceId,
                        Routes: userDataRoutes[workspaceId.toString()],
                    };
                    addUserDataForFunction(userPrivilegesTarget.UserID, postData);
                });
                if (userPrivilegesTarget.ReplicateFunctionID && userPrivilegesTarget.ReplicateFunctionID.length > 0) {
                    let replicateFuncIDs = userPrivilegesTarget.ReplicateFunctionID.join(',');
                    await replicateUserDataForFunction(userPrivilegesTarget.UserID, userPrivilegesTarget.FunctionID, replicateFuncIDs);
                }
                setShowEditUserPrivilegesDialog(false);
                setUserPrivilegesTarget({});
            } catch (e) {
                if (!showApiROErrorDialog) {
                    setShowApiROErrorDialog(getErrorMessage(e));
                }
            }
        }
    };

    const handleDeleteUserClose = async (option) => {
        if (option === 'CANCEL') {
            setShowDeleteUserDialog(false);
            setUserTarget({});
        } else if (option === 'DELETE') {
            let err;
            try {
                await deleteUser(userTarget.UserID);
                setShowDeleteUserDialog(false);
                setUserTarget({});
                populateUsers();
            } catch (e) {
                err = e;
                if (!showApiROErrorDialog) {
                    setShowApiROErrorDialog(getErrorMessage(err));
                }
            } finally {
                let logData = {
                    ActionId: 905,
                    Success: err ? false : true,
                    Metadata: userTarget.UserName,
                };
                updateUserActivity(logData);
            }
        }
    };

    return (
        <>
            <DataGrid
                height={props.windowDimensions.height - 275}
                dataSource={userData}
                keyExpr='UserID'
                showBorders={true}
                showColumnLines={true}
                showRowLines={true}
                allowColumnResizing={true}
                columnResizingMode={'nextColumn'}
                columnMinWidth={50}
                columnAutoWidth={true}
                ref={refUserListGrid}
            >
                <SearchPanel
                    visible={true}
                    width={240}
                    placeholder={t('Common.Search')}
                />
                <HeaderFilter visible={false} />
                <FilterRow
                    visible={false}
                    applyFilter={true}
                />
                <Pager
                    visible={true}
                    showInfo={true}
                />
                <Scrolling mode='virtual' />
                <Sorting mode='multiple' />
                <Toolbar>
                    <Item location='before'>
                        <DxButton
                            hint={t('AccountManagement.Users.Actions.AddUser')}
                            icon='add'
                            text={t('AccountManagement.Users.Actions.AddUser')}
                            onClick={(e) => {
                                handleEditUser('New', {});
                            }}
                        />
                    </Item>
                    <Item
                        location='after'
                        name='searchPanel'
                    />
                </Toolbar>
                <Column
                    dataField='UserName'
                    caption={t('AccountManagement.Users.Columns.UserName')}
                ></Column>
                <Column
                    dataField='FirstName'
                    caption={t('AccountManagement.Users.Columns.FirstName')}
                ></Column>
                <Column
                    dataField='LastName'
                    caption={t('AccountManagement.Users.Columns.LastName')}
                ></Column>
                <Column
                    dataField='Email'
                    caption={t('AccountManagement.Users.Columns.Email')}
                ></Column>
                <Column
                    dataField='RoleName'
                    caption={t('AccountManagement.Users.Columns.Role')}
                ></Column>
                <Column
                    dataField='GroupName'
                    caption={t('AccountManagement.Users.Columns.Group')}
                ></Column>
                <Column
                    type='buttons'
                    width='120px'
                    cellRender={(e) => {
                        return (
                            <div>
                                <DxButton
                                    hint={t('AccountManagement.Users.Actions.EditUserDataPrivileges')}
                                    icon='copy'
                                    onClick={() => {
                                        handleEditUserPrivileges(e.data);
                                    }}
                                />
                                &nbsp;
                                <DxButton
                                    hint={t('AccountManagement.Users.Actions.EditUser')}
                                    icon='edit'
                                    onClick={() => {
                                        handleEditUser('Edit', e.data);
                                    }}
                                />
                                &nbsp;
                                <DxButton
                                    hint={t('AccountManagement.Users.Actions.DeleteUser')}
                                    icon='trash'
                                    type='danger'
                                    onClick={() => {
                                        handleDeleteUser(e.data);
                                    }}
                                />
                            </div>
                        );
                    }}
                ></Column>
            </DataGrid>

            {showEditUserPrivilegesDialog && (
                <UserDataPrivilegesDialog
                    showEditUserPrivilegesDialog={showEditUserPrivilegesDialog}
                    userPrivilegesTarget={userPrivilegesTarget}
                    userPrivilegesGroupFunctionsData={userPrivilegesGroupFunctionsData}
                    userPrivilegesFunctions={userPrivilegesFunctions}
                    workspaceList={workspaceList}
                    workspaceRouteList={workspaceRouteList}
                    userPrivilegesReplicateGroupFunctionsData={userPrivilegesReplicateGroupFunctionsData}
                    userPrivilegesReplicateFunctions={userPrivilegesReplicateFunctions}
                    canAccessAll={canAccessAll}
                    setUserPrivilegesTarget={setUserPrivilegesTarget}
                    setCanAccessAll={setCanAccessAll}
                    setUserPrivilegesReplicateFunctions={setUserPrivilegesReplicateFunctions}
                    getUserDataForFunction={getUserDataForFunction}
                    setWorkspaceList={setWorkspaceList}
                    setWorkspaceRouteList={setWorkspaceRouteList}
                    setSavedUserFunctionData={setSavedUserFunctionData}
                    handleEditUserPrivilegesClose={handleEditUserPrivilegesClose}
                />
            )}

            {showEditUserDialog && (
                <AddEditUsersDialog
                    showEditUserDialog={showEditUserDialog}
                    userTarget={userTarget}
                    passwordMode={passwordMode}
                    editUserPasswordVisible={editUserPasswordVisible}
                    userPopoverVisible={userPopoverVisible}
                    confirmPasswordMode={confirmPasswordMode}
                    userMode={userMode}
                    assignableRoles={assignableRoles}
                    assignableGroups={assignableGroups}
                    updateUserForm={updateUserForm}
                    setPasswordMode={setPasswordMode}
                    handleEditUserClose={handleEditUserClose}
                    setEditUserPasswordVisible={setEditUserPasswordVisible}
                    setUserPopoverVisible={setUserPopoverVisible}
                    setConfirmPasswordMode={setConfirmPasswordMode}
                />
            )}

            {showDeleteUserDialog && (
                <DeleteUsers
                    open={showDeleteUserDialog}
                    handleClick={handleDeleteUserClose}
                    userTarget={userTarget}
                />
            )}
        </>
    );
};

export default Users;
