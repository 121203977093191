import React from 'react';
import { RODialog } from '../../../common';
import Grid from '@mui/material/Grid';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DataGrid, { Column, RowDragging, Scrolling, Sorting, MasterDetail } from 'devextreme-react/data-grid';
import DxButton from 'devextreme-react/button';
import { TextBox } from 'devextreme-react/text-box';
import { RODialogLabel } from '../../../common';
import SelectBox from 'devextreme-react/select-box';
import TagBox from 'devextreme-react/tag-box';
import { config } from '../../../../_helpers/config';
import { checkWorkspaceAccess } from '../../../../_actions/AccountManagement.actions';
import { getRouteList } from '../../../../_actions/Global.actions';
import { useTranslation } from 'react-i18next';

export const UserDataPrivilegesDialog = (props) => {
    const {
        showEditUserPrivilegesDialog,
        userPrivilegesTarget,
        userPrivilegesGroupFunctionsData,
        setUserPrivilegesTarget,
        setCanAccessAll,
        userPrivilegesFunctions,
        setUserPrivilegesReplicateFunctions,
        getUserDataForFunction,
        workspaceList,
        workspaceRouteList,
        setWorkspaceList,
        setWorkspaceRouteList,
        setSavedUserFunctionData,
        userPrivilegesReplicateGroupFunctionsData,
        canAccessAll,
        handleEditUserPrivilegesClose,
        userPrivilegesReplicateFunctions,
    } = props;
    const { t } = useTranslation();
    return (
        <RODialog
            maxWidth='md'
            fullWidth={true}
            open={showEditUserPrivilegesDialog}
            dialogTitle={t('AccountManagement.Users.Form.EditUserDataPrivileges')}
            dialogContent={
                <DialogContent>
                    <Grid
                        container
                        spacing={'10px'}
                        style={{ paddingTop: '10px' }}
                    >
                        <Grid
                            item
                            xs={1}
                        >
                            <RODialogLabel>{t('AccountManagement.Users.Form.User')}</RODialogLabel>
                        </Grid>
                        <Grid
                            item
                            xs={4}
                        >
                            <TextBox
                                name='UserName'
                                inputAttr={{ autocomplete: 'new-username' }}
                                value={userPrivilegesTarget.UserName}
                                disabled={true}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={2}
                        >
                            <RODialogLabel>{t('AccountManagement.Users.Form.Role')}</RODialogLabel>
                        </Grid>
                        <Grid
                            item
                            xs={5}
                        >
                            <TextBox
                                name='RoleName'
                                inputAttr={{ autocomplete: 'new-role' }}
                                value={userPrivilegesTarget.RoleName}
                                disabled={true}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={1}
                        >
                            <RODialogLabel>{t('AccountManagement.Users.Form.Function')}</RODialogLabel>
                        </Grid>
                        <Grid
                            item
                            xs={4}
                        >
                            <SelectBox
                                dataSource={userPrivilegesGroupFunctionsData}
                                valueExpr='id'
                                grouped={true}
                                displayExpr='functionName'
                                value={userPrivilegesTarget.FunctionID}
                                onValueChanged={async (e) => {
                                    let target = { ...userPrivilegesTarget };
                                    target.FunctionID = e.value;
                                    target.ReplicateFunctionID = [];
                                    setUserPrivilegesTarget(target);
                                    if (e.value) {
                                        checkWorkspaceAccess(e.value).then((res) => {
                                            setCanAccessAll(res.data);
                                        });
                                    }

                                    let replicateFunctions = [];
                                    userPrivilegesFunctions.forEach((item) => {
                                        if (e.value !== item.id) {
                                            replicateFunctions.push(item);
                                        }
                                    });
                                    setUserPrivilegesReplicateFunctions(replicateFunctions);
                                    if (e.value && userPrivilegesTarget && userPrivilegesTarget.UserID) {
                                        let funcUserData = null;
                                        try {
                                            funcUserData = await getUserDataForFunction(userPrivilegesTarget.UserID, e.value);
                                        } catch (e) {
                                            //Hanlde Not Found exception for new roles
                                        }
                                        if (funcUserData && funcUserData.data && funcUserData.data.length > 0) {
                                            setSavedUserFunctionData(funcUserData.data);
                                            let wsList = [...workspaceList];
                                            let wsRouteList = { ...workspaceRouteList };
                                            wsList.forEach((item) => {
                                                item.Status = 'Unselected';
                                                if (wsRouteList[item.Id] && wsRouteList[item.Id].length > 0) {
                                                    wsRouteList[item.Id].forEach((wsRtItem) => {
                                                        wsRtItem.Status = 'Unselected';
                                                    });
                                                }
                                            });

                                            if (funcUserData.data[0].Workspaces === 'ALL WORKSPACES') {
                                                wsList.forEach((item) => {
                                                    item.Status = 'Selected';
                                                });
                                            } else {
                                                funcUserData.data.forEach((userDataItem) => {
                                                    let wsItem;
                                                    for (let i = 0; i < wsList.length; i++) {
                                                        wsItem = wsList[i];
                                                        if (wsItem.Id.toString() === userDataItem.Workspaces) {
                                                            wsItem.Status = 'Selected';
                                                            if (userDataItem.Routes !== '') {
                                                                if (userDataItem.Routes === 'ALL ROUTES') {
                                                                    if (wsRouteList[wsItem.Id] && wsRouteList[wsItem.Id].length > 0) {
                                                                        wsRouteList[wsItem.Id].forEach((wsRtItem) => {
                                                                            wsRtItem.Status = 'Selected';
                                                                        });
                                                                    }
                                                                } else {
                                                                    let selRoutes = userDataItem.Routes.split(',');
                                                                    if (selRoutes.length > 0 && wsRouteList[wsItem.Id]) {
                                                                        let wsRtItem;
                                                                        for (let j = 0; j < wsRouteList[wsItem.Id].length; j++) {
                                                                            wsRtItem = wsRouteList[wsItem.Id][j];
                                                                            if (selRoutes.includes(wsRtItem.Id.toString())) {
                                                                                wsRtItem.Status = 'Selected';
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            break;
                                                        }
                                                    }
                                                });
                                            }
                                            setWorkspaceList(wsList);
                                            setWorkspaceRouteList(wsRouteList);
                                        } else {
                                            setSavedUserFunctionData(null);
                                            let wsList = [...workspaceList];
                                            let wsRouteList = { ...workspaceRouteList };
                                            wsList.forEach((item) => {
                                                item.Status = 'Unselected';
                                                if (wsRouteList[item.Id] && wsRouteList[item.Id].length > 0) {
                                                    wsRouteList[item.Id].forEach((wsRtItem) => {
                                                        wsRtItem.Status = 'Unselected';
                                                    });
                                                }
                                            });
                                            setWorkspaceList(wsList);
                                            setWorkspaceRouteList(wsRouteList);
                                        }
                                    }
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={2}
                        >
                            <RODialogLabel>{t('AccountManagement.Users.Form.ReplicateTo')}</RODialogLabel>
                        </Grid>
                        <Grid
                            item
                            xs={5}
                        >
                            <TagBox
                                dataSource={userPrivilegesReplicateGroupFunctionsData}
                                valueExpr='id'
                                grouped={true}
                                showSelectionControls={true}
                                applyValueMode='useButtons'
                                displayExpr='functionName'
                                disabled={userPrivilegesReplicateFunctions.length === 0}
                                onValueChanged={(e) => {
                                    let target = { ...userPrivilegesTarget };
                                    target.ReplicateFunctionID = e.value;
                                    setUserPrivilegesTarget(target);
                                }}
                            />
                        </Grid>
                        {userPrivilegesTarget.FunctionID && (
                            <Grid
                                item
                                xs={5}
                            >
                                <RODialogLabel>
                                    {t('AccountManagement.Users.Form.AssignableWorkspaces')} (
                                    {
                                        workspaceList.filter((obj) => {
                                            return obj.Status === 'Unselected';
                                        }).length
                                    }
                                    )
                                </RODialogLabel>
                                <DataGrid
                                    height={275}
                                    dataSource={
                                        canAccessAll
                                            ? workspaceList
                                            : workspaceList.filter((obj) => {
                                                  return obj.Status !== 'Selected';
                                              })
                                    }
                                    keyExpr='Id'
                                    showBorders={true}
                                    showRowLines={true}
                                    filterSyncEnabled={true}
                                    filterValue={['Status', '=', 'Unselected']}
                                >
                                    <RowDragging
                                        showDragIcons={true}
                                        group='workspaces'
                                    />
                                    <Scrolling mode='virtual' />
                                    <Sorting mode='none' />
                                    <Column
                                        dataField='Name'
                                        caption={t('AccountManagement.Users.Form.Name')}
                                    ></Column>
                                    <Column
                                        dataField='Status'
                                        visible={false}
                                    ></Column>
                                </DataGrid>
                            </Grid>
                        )}
                        {userPrivilegesTarget.FunctionID && (
                            <Grid
                                item
                                xs={2}
                                justifyContent='center'
                            >
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <DxButton
                                    icon='chevrondoubleright'
                                    text={t('AccountManagement.Users.Form.AssignAll')}
                                    width='120px'
                                    onClick={() => {
                                        let wsList = [...workspaceList];
                                        wsList.forEach((item) => {
                                            item.Status = 'Selected';
                                        });
                                        setWorkspaceList(wsList);
                                        setWorkspaceRouteList({});
                                    }}
                                />
                                <br />
                                <br />
                                <DxButton
                                    icon='chevrondoubleleft'
                                    text={t('AccountManagement.Users.Form.RemoveAll')}
                                    width='120px'
                                    onClick={() => {
                                        let wsList = [...workspaceList];
                                        wsList.forEach((item) => {
                                            item.Status = 'Unselected';
                                        });
                                        setWorkspaceList(wsList);
                                        setWorkspaceRouteList({});
                                    }}
                                />
                            </Grid>
                        )}
                        {userPrivilegesTarget.FunctionID && (
                            <Grid
                                item
                                xs={5}
                            >
                                <RODialogLabel>
                                    {t('AccountManagement.Users.Form.AssignedWorkspaces')} (
                                    {
                                        workspaceList.filter((obj) => {
                                            return obj.Status === 'Selected';
                                        }).length
                                    }
                                    )
                                </RODialogLabel>
                                <DataGrid
                                    height={275}
                                    dataSource={
                                        workspaceList.filter((obj) => {
                                            return obj.Status === 'Selected';
                                        }).length == workspaceList.length && canAccessAll
                                            ? [{ Id: 0, Name: 'All Workspaces', Status: 'Selected' }]
                                            : workspaceList
                                    }
                                    keyExpr='Id'
                                    showBorders={true}
                                    showRowLines={true}
                                    filterSyncEnabled={true}
                                    filterValue={['Status', '=', 'Selected']}
                                >
                                    <MasterDetail
                                        autoExpandAll={false}
                                        enabled={
                                            config.FUNCTIONS_THAT_ALLOW_ROUTE_SELECTION.includes(userPrivilegesTarget.FunctionID) &&
                                            workspaceList.filter((obj) => {
                                                return obj.Status === 'Selected';
                                            }).length != workspaceList.length
                                                ? true
                                                : false
                                        }
                                        component={(props) => {
                                            return (
                                                <Grid
                                                    container
                                                    spacing={'10px'}
                                                    style={{ paddingTop: '10px' }}
                                                >
                                                    <Grid
                                                        item
                                                        xs={5}
                                                    >
                                                        <DataGrid
                                                            width={125}
                                                            height={150}
                                                            dataSource={workspaceRouteList[props.data.key]}
                                                            keyExpr='Id'
                                                            showBorders={true}
                                                            showRowLines={true}
                                                            filterSyncEnabled={true}
                                                            filterValue={['Status', '=', 'Unselected']}
                                                        >
                                                            <RowDragging
                                                                showDragIcons={true}
                                                                group='workspaces'
                                                            />
                                                            <Scrolling mode='virtual' />
                                                            <Sorting mode='none' />
                                                            <Column
                                                                dataField='Id'
                                                                caption={t('AccountManagement.Users.Form.Route')}
                                                            />
                                                            <Column
                                                                dataField='Status'
                                                                visible={false}
                                                            ></Column>
                                                        </DataGrid>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={2}
                                                        justifyContent='center'
                                                    >
                                                        <br />
                                                        <br />
                                                        <DxButton
                                                            icon='chevrondoubleright'
                                                            hint={t('AccountManagement.Users.Form.AssignAll')}
                                                            width='50px'
                                                            onClick={() => {
                                                                let wsRteLst = { ...workspaceRouteList };
                                                                wsRteLst[props.data.key].forEach((item) => {
                                                                    item.Status = 'Selected';
                                                                });
                                                                setWorkspaceRouteList(wsRteLst);
                                                            }}
                                                        />
                                                        <br />
                                                        <br />
                                                        <DxButton
                                                            icon='chevrondoubleleft'
                                                            hint={t('AccountManagement.Users.Form.RemoveAll')}
                                                            width='50px'
                                                            onClick={() => {
                                                                let wsRteLst = { ...workspaceRouteList };
                                                                wsRteLst[props.data.key].forEach((item) => {
                                                                    item.Status = 'Unselected';
                                                                });
                                                                setWorkspaceRouteList(wsRteLst);
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={5}
                                                    >
                                                        <DataGrid
                                                            width={125}
                                                            height={150}
                                                            dataSource={
                                                                workspaceRouteList[props.data.key] &&
                                                                workspaceRouteList[props.data.key].filter((obj) => {
                                                                    return obj.Status === 'Selected';
                                                                }).length === workspaceRouteList[props.data.key].length
                                                                    ? [{ Id: 'All Routes', Name: 'All Routes', Status: 'Selected' }]
                                                                    : workspaceRouteList[props.data.key]
                                                            }
                                                            keyExpr='Id'
                                                            showBorders={true}
                                                            showRowLines={true}
                                                            filterSyncEnabled={true}
                                                            filterValue={['Status', '=', 'Selected']}
                                                        >
                                                            <RowDragging
                                                                showDragIcons={true}
                                                                group='workspaces'
                                                                onAdd={async (e) => {
                                                                    if (e.itemData) {
                                                                        let wsRouteList = { ...workspaceRouteList };
                                                                        for (let i = 0; i < wsRouteList[props.data.key].length; i++) {
                                                                            if (wsRouteList[props.data.key][i].Id === e.itemData.Id) {
                                                                                wsRouteList[props.data.key][i].Status = 'Selected';
                                                                                break;
                                                                            }
                                                                        }
                                                                        setWorkspaceRouteList(wsRouteList);
                                                                    }
                                                                }}
                                                                onRemove={(e) => {
                                                                    if (e.itemData) {
                                                                        let wsRouteList = { ...workspaceRouteList };
                                                                        for (let i = 0; i < wsRouteList[props.data.key].length; i++) {
                                                                            if (wsRouteList[props.data.key][i].Id === e.itemData.Id) {
                                                                                wsRouteList[props.data.key][i].Status = 'Unselected';
                                                                                break;
                                                                            }
                                                                        }
                                                                        setWorkspaceRouteList(wsRouteList);
                                                                    }
                                                                }}
                                                            />
                                                            <Scrolling mode='virtual' />
                                                            <Sorting mode='none' />
                                                            <Column
                                                                dataField='Id'
                                                                caption={t('AccountManagement.Users.Form.Route')}
                                                            />
                                                            <Column
                                                                dataField='Status'
                                                                visible={false}
                                                            ></Column>
                                                        </DataGrid>
                                                    </Grid>
                                                </Grid>
                                            );
                                        }}
                                    />
                                    <RowDragging
                                        showDragIcons={true}
                                        group='workspaces'
                                        onAdd={async (e) => {
                                            if (e.itemData) {
                                                let wsList = [...workspaceList];
                                                let wsRouteList = { ...workspaceRouteList };
                                                for (let i = 0; i < wsList.length; i++) {
                                                    if (wsList[i].Id === e.itemData.Id) {
                                                        wsList[i].Status = 'Selected';
                                                        const res = await getRouteList(e.itemData.Id, 0); //Routes for all service days
                                                        let wsRteLst = res.data;
                                                        wsRteLst.forEach((item) => {
                                                            item.Status = 'Unselected';
                                                        });
                                                        wsRouteList[e.itemData.Id] = wsRteLst;
                                                        break;
                                                    }
                                                }
                                                setWorkspaceList(wsList);
                                                setWorkspaceRouteList(wsRouteList);
                                            }
                                        }}
                                        onRemove={(e) => {
                                            if (e.itemData) {
                                                let wsList = [...workspaceList];
                                                let wsRouteList = { ...workspaceRouteList };
                                                if (e.itemData.Id === 0) {
                                                    wsList.forEach((item) => {
                                                        item.Status = 'Unselected';
                                                    });
                                                    setWorkspaceRouteList({});
                                                } else {
                                                    for (let i = 0; i < wsList.length; i++) {
                                                        if (wsList[i].Id === e.itemData.Id) {
                                                            wsList[i].Status = 'Unselected';
                                                            delete wsRouteList[e.itemData.Id];
                                                            break;
                                                        }
                                                    }
                                                }
                                                setWorkspaceList(wsList);
                                                setWorkspaceRouteList(wsRouteList);
                                            }
                                        }}
                                    />
                                    <Scrolling mode='virtual' />
                                    <Sorting mode='none' />
                                    <Column
                                        dataField='Name'
                                        caption={t('AccountManagement.Users.Form.Name')}
                                    ></Column>
                                    <Column
                                        dataField='Status'
                                        visible={false}
                                    ></Column>
                                </DataGrid>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
            }
            dialogActions={
                <DialogActions style={{ justifyContent: 'right' }}>
                    <DxButton
                        id='button'
                        text={t('Common.Save')}
                        type='default'
                        useSubmitBehavior={true}
                        disabled={!userPrivilegesTarget.FunctionID}
                        onClick={() => {
                            handleEditUserPrivilegesClose('SAVE');
                        }}
                    />
                    <DxButton
                        id='button'
                        text={t('Common.Cancel')}
                        type='normal'
                        useSubmitBehavior={false}
                        onClick={() => {
                            handleEditUserPrivilegesClose('CANCEL');
                        }}
                    />
                </DialogActions>
            }
        />
    );
};
