import React from 'react';
import { getGroups, addGroup, updateGroup, deleteGroup } from '../../../_actions/AccountManagement.actions';
import DataGrid, { Column, Scrolling, Sorting, Pager, FilterRow, HeaderFilter, SearchPanel, Toolbar, Item } from 'devextreme-react/data-grid';
import DxButton from 'devextreme-react/button';
import { updateUserActivity } from '../../../_actions/Global.actions';
import { AddEditGroupsDialog } from './Dialogs/AddEdit';
import DeleteGroups from './Dialogs/Delete';
import { GlobalROContext } from '../../common';
import { getErrorMessage } from '../../../_helpers/common';
import { useTranslation } from 'react-i18next';

const Groups = (props) => {
    const refGroupListGrid = React.useRef();
    const { t } = useTranslation();
    const [groupData, setGroupData] = React.useState(null);
    const [showEditGroupDialog, setShowEditGroupDialog] = React.useState(false);
    const [showDeleteGroupDialog, setShowDeleteGroupDialog] = React.useState(false);
    const [groupMode, setGroupMode] = React.useState('');
    const [groupTarget, setGroupTarget] = React.useState({});
    const { showApiROErrorDialog, setShowApiROErrorDialog } = React.useContext(GlobalROContext);

    const populateGroups = async () => {
        const groupsResponse = await getGroups();
        if (groupsResponse && groupsResponse.data && groupsResponse.data.length > 0) {
            setGroupData(groupsResponse.data);
        } else {
            setGroupData([]);
        }
    };

    React.useEffect(() => {
        populateGroups();
    }, []);

    const updateGroupForm = (field, value) => {
        let t = { ...groupTarget };
        t[field] = value;
        setGroupTarget(t);
    };

    const handleEditGroup = (mode, data) => {
        setGroupMode(mode);
        setGroupTarget(data);
        setShowEditGroupDialog(true);
    };

    const handleDeleteGroup = (data) => {
        setGroupMode('Delete');
        setGroupTarget(data);
        setShowDeleteGroupDialog(true);
    };

    const handleEditGroupClose = async (option) => {
        if (option === 'CANCEL') {
            setShowEditGroupDialog(false);
            setGroupTarget({});
        } else if (option === 'SAVE') {
            let err;
            let ActionId;
            try {
                let postData = {
                    Name: groupTarget.Name,
                    Description: groupTarget.Description,
                };
                if (groupMode === 'New') {
                    ActionId = 908;
                    await addGroup(postData);
                } else if (groupMode === 'Edit') {
                    ActionId = 910;
                    await updateGroup(groupTarget.Id, postData);
                }
                populateGroups();
                setShowEditGroupDialog(false);
                setGroupTarget({});
            } catch (e) {
                err = e;
                if (!showApiROErrorDialog) {
                    setShowApiROErrorDialog(getErrorMessage(err));
                }
            } finally {
                let logData = {
                    ActionId,
                    Success: err ? false : true,
                    Metadata: groupTarget.Name,
                };
                updateUserActivity(logData);
            }
        }
    };

    const handleDeleteGroupClose = async (option) => {
        if (option === 'CANCEL') {
            setShowDeleteGroupDialog(false);
            setGroupTarget({});
        } else if (option === 'DELETE') {
            let err;
            try {
                await deleteGroup(groupTarget.Id);
                setShowDeleteGroupDialog(false);
                setGroupTarget({});
                populateGroups();
            } catch (e) {
                err = e;
                if (!showApiROErrorDialog) {
                    setShowApiROErrorDialog(getErrorMessage(err));
                }
            } finally {
                let logData = {
                    ActionId: 912,
                    Success: err ? false : true,
                    Metadata: groupTarget.Name,
                };
                updateUserActivity(logData);
            }
        }
    };

    return (
        <>
            <DataGrid
                height={props.windowDimensions.height - 275}
                dataSource={groupData}
                keyExpr='Id'
                showBorders={true}
                showColumnLines={true}
                showRowLines={true}
                allowColumnResizing={true}
                columnResizingMode={'nextColumn'}
                columnMinWidth={50}
                columnAutoWidth={true}
                ref={refGroupListGrid}
            >
                <SearchPanel
                    visible={true}
                    width={240}
                    placeholder={t('Common.Search')}
                />
                <HeaderFilter visible={false} />
                <FilterRow
                    visible={false}
                    applyFilter={true}
                />
                <Pager
                    visible={true}
                    showInfo={true}
                />
                <Scrolling mode='virtual' />
                <Sorting mode='multiple' />
                <Toolbar>
                    <Item location='before'>
                        <DxButton
                            hint={t('AccountManagement.Groups.Actions.AddGroup')}
                            icon='add'
                            text={t('AccountManagement.Groups.Actions.AddGroup')}
                            onClick={(e) => {
                                handleEditGroup('New', {});
                            }}
                        />
                    </Item>
                    <Item
                        location='after'
                        name='searchPanel'
                    />
                </Toolbar>
                <Column
                    dataField='Name'
                    caption={t('AccountManagement.Groups.Columns.GroupName')}
                    sortIndex='0'
                ></Column>
                <Column
                    dataField='Description'
                    caption={t('AccountManagement.Groups.Columns.Description')}
                ></Column>
                <Column
                    dataField='UserCount'
                    caption={t('AccountManagement.Groups.Columns.NumberOfUsers')}
                ></Column>
                <Column
                    type='buttons'
                    width='80px'
                    cellRender={(e) => {
                        return (
                            <div>
                                <DxButton
                                    hint={t('AccountManagement.Groups.Actions.EditGroup')}
                                    icon='edit'
                                    onClick={() => {
                                        handleEditGroup('Edit', e.data);
                                    }}
                                />
                                &nbsp;
                                <DxButton
                                    hint={t('AccountManagement.Groups.Actions.DeleteGroup')}
                                    icon='trash'
                                    type='danger'
                                    disabled={e.data.UserCount > 0 ? true : false}
                                    onClick={() => {
                                        handleDeleteGroup(e.data);
                                    }}
                                />
                            </div>
                        );
                    }}
                ></Column>
            </DataGrid>

            {showEditGroupDialog && (
                <AddEditGroupsDialog
                    open={showEditGroupDialog}
                    groupMode={groupMode}
                    handleChange={updateGroupForm}
                    handleClick={handleEditGroupClose}
                    groupTarget={groupTarget}
                />
            )}
            {showDeleteGroupDialog && (
                <DeleteGroups
                    open={showDeleteGroupDialog}
                    handleClick={handleDeleteGroupClose}
                    groupTarget={groupTarget}
                />
            )}
        </>
    );
};

export default Groups;
